@import '../../assets/scss/variables';

.signup-section{
    width: 90%;
    max-width: 650px;
    margin: 2rem auto 0rem;
    color: $white;
    padding-bottom: 10rem;
    h1{
        margin: 0rem 0rem 1rem;
        font-size: 50px;
        font-weight: 500;
        line-height: 120%;
    }
    p{
        font-size: 22px;
        font-weight: 400;
        line-height: 160%;
        color: #CED2DD;
        margin: 0px;
    }
    .error-message{
        color: $primary;
        font-size: 13px;
        margin: 5px 2px;
    }
    .signup-wrapper{
        text-align: left;
        border-radius: 9px;
        background: $purple-dark;
        padding: 36px;
        margin-top: 3rem;
        .form-block{
            .input-field{
                margin-bottom: 0.875rem;
                label{
                    font-size: 20px;
                    font-weight: 400;
                    line-height: 160%;
                }
                .p-inputtext{
                    border-radius: 8px;
                    border: 1px solid rgba(226, 227, 238, 0.13);
                    background: rgba(22, 0, 51, 0.10);
                    padding: 14px 20px;
                    color: $white;
                    font-family: $font-base;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 160%;
                    width: 100%;
                    .p-input-icon{
                        svg{
                            &:focus{
                                outline: none;
                                box-shadow: none;
                            }
                        }
                    }
                    &::placeholder{
                        color: #b7b7b7;
                    }
                }
                .p-icon-field{
                    width: 100%;
                }
            }
            .signup-button{
                background: $accent;
                border-radius: 12px;
                transition: 0.8s;
                padding: 14px 40px;
                border: none;
                margin-top: 0.5rem;
                .p-button-label{
                    font-size: 21px;
                    font-weight: 600;
                    position: relative;
                    z-index: 0;
                    line-height: 35.567px;
                }
                &:hover{
                    background: $primary;
                }
            }
            .link-p{
                font-size: 22px;
                font-weight: 400;
                line-height: 160%;
                color: #DADDE5;
                a{
                    font-weight: 500;
                    color: $white;
                    margin-left: 5px;
                    &:hover{
                        color: $primary;
                    }
                }
            }
            .forgot-pass-p{
                color: #DADDE5;
                font-size: 22px;
                font-weight: 400;
                line-height: 160%;
                margin: 2rem 0rem 0rem;
                a{
                    color: #DADDE5;
                    &:hover{
                        color: $primary;
                    }
                }
            }
            .social-button{
                flex-direction: row-reverse;
                padding: 15px 10px;
                border-radius: 8px;
                background: #E4E9F1;
                border: none;
                width: 100%;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                .button-label{
                    font-size: 15px;
                    font-weight: 500;
                    line-height: 160%;
                    color: #181B27;
                    margin-left: 8px;
                }
                .p-image{
                    line-height: 0;
                    img{
                        height: 24px;
                    }
                }
                &:hover{
                    opacity: 0.8;
                }
            }
        }
        .delete-button{
            color: $white;
            .pi{
                margin-right: 10px;
            }
            &:hover{
               color: $primary;
            }
        }
    }
        .no-spinner::-webkit-outer-spin-button,
        .no-spinner::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    
        /* Hide spinners in Firefox */
        .no-spinner[type='number'] {
            -moz-appearance: textfield;
        }
}


// Responsive CSS

@media (max-width:768px){
    .signup-section{
        h1{
            font-size: 34px;
        }
        p{
            font-size: 18px;
        }
        .signup-wrapper{
            padding: 30px 20px;
            .form-block{
                .input-field{
                    .p-inputtext{
                        font-size: 16px;
                    }
                    label{
                        font-size: 18px; 
                    }
                }
                .forgot-pass-p, .link-p{
                    font-size: 18px;
                }
                .signup-button{
                    padding: 10px 40px;
                    .p-button-label{
                        font-size: 18px;
                    }
                }
            }
        }
    }
}