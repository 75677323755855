@import '../../assets/scss/variables';

.notifications{
    &-section{
        width: 90%;
        max-width: 1100px;
        margin: 0 auto;
        color: $white;
        padding: 1rem 0rem 10rem;
        h1{
            color: $white;
            font-size: 52px;
            font-weight: 500;
            line-height: 160%;
            margin: 5px 0px;
        }
        p{
            color: $primary;
            font-size: 22px;
            font-weight: 500;
            line-height: 160%;
            margin: 0px;
        }
        .message-tag{
            text-align: center;
            color: $white;
            font-size: 18px;
            margin-top: 6rem;
            font-weight: 400;
        }
    }
    &-list{
        margin-top: 5rem;
        .notifications-accordion{
            .p-accordion-tab{
                .p-accordion-header{
                    .p-accordion-header-link{
                        background: $main-bg;
                        border: none;
                        border-bottom: 1px solid #343434;
                        border-radius: 0px;
                        padding: 2rem 0rem;
                        .p-icon{
                            display: none;
                        }
                        .simcard-icon{
                            border-radius: 71.094px;
                            background: #291F36;
                            width: 52px;
                            min-width: 52px;
                            height: 52px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                        .heading{
                            color: $white;
                            font-size: 22px;
                            font-weight: 400;
                            line-height: 160%;
                            margin-left: 10px;
                        }
                        .time{
                            color: #A5A5B9;
                            font-size: 20px;
                            font-weight: 400;
                            line-height: 160%;
                        }
                    }
                }
            }
            .p-accordion-content{
                background: none;
                border: none;
                padding: 1.25rem 0rem;
                overflow: auto;
                p{
                    color: $white;
                    font-size: 20px;
                    font-weight: 400;
                    line-height: 160%;
                }
                a{
                    color: $primary;
                }
                table{
                    border-color: #343434 !important;
                    tr th{
                        border-bottom: 1px solid #343434 !important;
                    }
                }
            }
            .qrcode-div{
                display: flex;
                width: 100%;
                align-items: center;
                justify-content: center;
            }
        }
        .loading-div{
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 40px;
        }
    }
}

// Responsive CSS

@media (max-width:991px){
    .notifications-list .notifications-accordion .p-accordion-content{
        p{
            font-size: 16px;
        }
        .bg-contain{
            display: block !important;
            .notifications-content > div{
                width: auto !important;
            }
        }
    }
}

@media (max-width:768px){
    .notifications-list{
        .p-accordion-header-text{
            .flex{
                display: block !important;
                .left{
                    margin-bottom: 0.8rem;
                    .heading{
                        margin-left: 0px !important;
                        margin-top: 10px;
                        display: block;
                    }
                }
            }
        }
    }
    .notifications-section h1{
        font-size: 34px;
    }
}