@import '../../assets/scss/variables';

.page-404-section{
    position: relative;
    background: $main-bg;
    .container{
        width: 90%;
        max-width: 1565px;
        margin: 0 auto;
        color: $white;
        height: 100%;
        min-height: 100dvh;
        display: flex;
        align-items: center;
        justify-content: center;
        h1{
            margin: 15px 0px 0px;
            font-size: 96px;
            font-weight: 600;
            line-height: 92%;
            letter-spacing: -4.8px;
        }
        span{
            color: $accent;
            font-size: 24px;
            font-weight: 600;
            line-height: 29.325px;
        }
        p{
            font-size: 32px;
            font-weight: 400;
            line-height: 42px;
            letter-spacing: -1.6px;
            margin-right: 10rem;
        }
        .goto-button{
            background: $accent;
            border-radius:8px;
            transition: 0.8s;
            padding: 14px 30px;
            border: none;
            margin-top: 3.625rem;
            max-width: 325px;
            .p-button-label{
                font-size: 23px;
                font-weight: 600;
                position: relative;
                z-index: 2;
                line-height: 36px;
                color: $white;
            }
            &:hover{
                &:after{
                    width: 100%;
                }
            }
            &:after{
                position: absolute;
                content: "";
                top: 0;
                left: 0;
                width: 0;
                height: 100%;
                background: $primary;
                transition: all .35s;
            }
        }
        .error-image{
            img{
                width: 100%;
                max-width: 580px;
            }
        }
    }
    &::before{
        content: '';
        width: 365px;
        height: 365px;
        background: $primary;
        position: absolute;
        border-radius: 100%;
        left: 4rem;
        bottom: 0rem;
        z-index:0;
        filter: blur(190px);
        -webkit-filter: blur(190px);
        opacity: 0.5;
    }
    &::after{
        content: '';
        width: 225px;
        height: 225px;
        background: $accent;
        position: absolute;
        border-radius: 100%;
        right: 4rem;
        top: 0rem;
        z-index:0;
        filter: blur(190px);
        -webkit-filter: blur(190px);
    } 
}

@media (max-width:991px){
    .page-404-section{
        .container{
            text-align: center;
            h1{
                font-size: 40px;
                letter-spacing: -1.8px;
            }
            p{
                font-size: 20px;
                line-height: 33px;
                margin-right: 0rem;
            }
            .goto-button{
                margin-top: 1.5rem;
            }
        }
    }
}

@media (min-width:991px) and (max-width:1199px){
    .page-404-section{
        .container{
            h1{
                font-size: 50px;
                letter-spacing: -1.8px;
            }
            p{
                font-size: 22px;
                line-height: 33px;
                margin-right: 0rem;
            }
            .goto-button{
                margin-top: 1.5rem;
            }
        }
    }
}