@import '../../assets/scss/variables';

.myplans-section{
    width: 90%;
    max-width: 1512px;
    margin: 0 auto;
    .plans-grid-section{
        margin-top: 4.3125rem;
        .p-tabview{
            .p-tabview-nav-container{
                .p-tabview-nav-content{
                    .p-tabview-nav{
                        display: inline-flex;
                        background: $purple-dark;
                        padding: 7px 7px 9px;
                        border-radius: 4px;
                        border: 1px solid $purple-dark;
                        .p-unselectable-text{
                            .p-tabview-nav-link{
                                padding: 18px 42px;
                                font-size: 21px;
                                font-weight: 500;
                                color: $white;
                                border: none;
                                border-radius: 4px;
                                background: $purple-dark;
                                min-width: 240px;
                                display: block;
                            }
                            &.p-tabview-selected{
                                .p-tabview-nav-link{
                                    background: $accent;
                                    box-shadow: 2px 4px 18px 0px rgba(111, 110, 151, 0.15);
                                }
                            }
                        }
                        .p-tabview-ink-bar{
                            display: none;
                        }
                    }
                }
            }
            .p-tabview-panels{
                padding: 0px;
                background: transparent;
                .iccid-h2{
                    color: $white;
                    font-size: 24px;
                    font-weight: 400;
                    line-height: 160%;
                    margin: 0px;
                    padding: 2.8125rem 0rem;
                }
                .plans-table{
                    margin-top: 2.8125rem;
                    .p-datatable-wrapper{
                        border-radius: 16px;
                        .p-datatable-table{
                            .p-datatable-thead{
                                tr{
                                    th{
                                        background: $purple-dark;
                                        border-bottom: 1.406px solid rgba(234, 236, 240, 0.09);
                                        padding: 1rem 2rem;
                                        .p-column-header-content{
                                            justify-content: center;
                                        }
                                        &:first-child{
                                            .p-column-header-content{
                                                justify-content: flex-start;
                                            }
                                        }
                                        &:last-child{
                                            .p-column-header-content{
                                                justify-content: flex-start;
                                            }
                                        }
                                    }
                                }
                                .p-column-header-content{
                                    .p-column-title{
                                        color: rgba(255, 255, 255, 0.75);
                                        font-size: 19.688px;
                                        font-weight: 400;
                                        line-height: 160%;
                                    }
                                }
                            }
                            .p-datatable-tbody{
                                tr{
                                    background: $purple-dark;
                                    td{
                                        font-size: 22px;
                                        padding: 1rem 2rem;
                                        font-weight: 400;
                                        line-height: 160%;
                                        color: $white;
                                        border-bottom: 1.406px solid rgba(234, 236, 240, 0.09);
                                        .add-bundle-button{
                                            padding: 10px 22.5px;
                                            border-radius: 8px;
                                            border: none;
                                            background:$white;
                                            .p-button-label{
                                                color: $purple-dark;
                                                font-size: 19.688px;
                                                font-weight: 500;
                                                line-height: 160%;
                                            }
                                            &:hover{
                                                background: $accent;
                                                .p-button-label{
                                                    color: $white;
                                                }
                                            }
                                        }
                                        &.last-column{
                                            width: 15%;
                                            white-space:nowrap;
                                            .pi{
                                                font-size: 25px;
                                                cursor: pointer;
                                                &:hover{
                                                    color: $primary;
                                                }
                                                &.disabled{
                                                    cursor: default;
                                                    opacity: 0.5;
                                                    &:hover{
                                                        color: $white;
                                                    }
                                                }
                                            }
                                            a{
                                                color: $white;
                                            }
                                        }
                                        .expiry-column{
                                            text-align: center;
                                            p{
                                                margin: 0px;
                                                font-size: 20px;
                                                font-weight: 500;
                                                line-height: 160%;
                                            }
                                        }
                                        .esim-column{
                                            display: flex;
                                            gap:12px;
                                            .p-image{
                                                line-height: 0px;
                                                position: relative;
                                                top:6px;
                                            }
                                            p{
                                                margin: 0px;
                                                color: rgba(255, 255, 255, 0.80);
                                                font-size: 20px;
                                                font-weight: 400;
                                                line-height: 160%;
                                            }
                                            .esim-name{
                                                color: $white;
                                            }
                                            .tag-edit{
                                                margin-bottom: 5px;
                                                display: inline-block;
                                                .custom-view-wrapper{
                                                    flex-direction: row-reverse;
                                                    display: flex;
                                                    align-items: center;
                                                    justify-content: flex-end;
                                                    .custom-view-class{
                                                        border-bottom: 2px solid $white;
                                                        line-height: 28px;
                                                        margin-left: 15px;
                                                        font-weight: 600;
                                                        font-size: 20px;
                                                    }
                                                    input{
                                                        border-radius: 8px;
                                                        border: 1px solid rgba(226, 227, 238, 0.13);
                                                        background: rgba(22, 0, 51, 0.10);
                                                        padding: 7px 15px;
                                                        color: $white;
                                                        font-family: $font-base;
                                                        font-size: 13px;
                                                        font-weight: 400;
                                                        line-height: 160%;
                                                    }
                                                }
                                                button{
                                                    background: none;
                                                    border: none;
                                                    min-width: 20px;
                                                    height: 20px;
                                                    display: flex;
                                                    align-items: center;
                                                    justify-content: center;
                                                    padding: 0px;
                                                    cursor: pointer;
                                                    margin-left: 0px;
                                                    &.custom-edit-button{
                                                        position: relative;
                                                        top: 5px;
                                                        left: 0px;
                                                        &::before{
                                                            content: '';
                                                            position: absolute;
                                                            width: 20px;
                                                            height: 20px;
                                                            background: url('../../assets/images/edit-icon.png') no-repeat;
                                                        }
                                                    }
                                                    &.custom-save-button{
                                                        margin-right: 5px;
                                                        &::before{
                                                            content: "\e909";
                                                            font-family: primeicons;
                                                            color: #31A339;
                                                            background: #281443;
                                                            width: 33px;
                                                            height: 33px;
                                                            display: flex;
                                                            align-items: center;
                                                            justify-content: center;
                                                            font-size: 16px;
                                                            border-radius: 10px;
                                                        }
                                                        &:hover{
                                                            &::before{
                                                                background: #31A339;
                                                                color: $white;
                                                            }
                                                        }
                                                    }
                                                    &.custom-cancel-button{
                                                        margin-right: 8px;
                                                        &::before{
                                                            content: "\e90b";
                                                            font-family: primeicons;
                                                            color: #C74B4B;
                                                            background: #281443;
                                                            width: 33px;
                                                            height: 33px;
                                                            display: flex;
                                                            align-items: center;
                                                            justify-content: center;
                                                            font-size: 16px;
                                                            border-radius: 10px;
                                                        }
                                                        &:hover{
                                                            &::before{
                                                                background: #C74B4B;
                                                                color: $white;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        .progress-bar{
                                            height: 11px;
                                            border-radius: 25px;
                                            .p-progressbar-value{
                                                background: linear-gradient(90deg, #6716CE 4.57%, #B573F6 89.96%);
                                                border-radius: 25px;
                                                .p-progressbar-label{
                                                    display: none;
                                                }
                                            }
                                        }
                                        &[colspan="5"]{
                                            padding: 0; 
                                        }
                                    }
                                    &:last-child{
                                        td{
                                            border: none;
                                        }
                                    }
                                    &.p-datatable-row-expansion{
                                        td{
                                            background: #110028;
                                            .inner-table{
                                                margin-top: 0rem;
                                                .p-datatable-thead{
                                                    display: none;
                                                }
                                            }
                                            &:nth-child(2){
                                                min-width: 30%;
                                                max-width: 30%;
                                                width: 23%;
                                            }
                                        }
                                    }
                                    &.p-datatable-emptymessage{
                                        td{
                                            padding: 20px 30px;
                                            text-align: center;
                                            font-size: 18px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .bundles-list{
            text-align: left;
            .bundle{
                border-radius: 26.79px;
                background: #160033;
                padding: 2rem;
                color: $white;
                background-image: url('../../assets/images/data-plan-bg.png');
                .dot{
                    width: 35px;
                    height: 35px;
                    min-width: 35px;
                    border-radius: 25px;
                    background: rgba(193, 241, 192, 0.32);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .active{
                        width: 12px;
                        height: 12px;
                        background: #19FF15;
                        display: block;
                        border-radius: 10px;
                    }
                    .pending{
                        width: 12px;
                        height: 12px;
                        background: #7A7A7A;
                        display: block;
                        border-radius: 10px;
                    }
                    .expired{
                        width: 12px;
                        height: 12px;
                        background: #FFA600;
                        display: block;
                        border-radius: 10px;
                    }
                }
                h2{
                    margin: 0px;
                    font-size: 25.43px;
                    font-weight: 600;
                    line-height: 160%;
                    display: flex;
                    gap: 16px;
                }
                p{
                    margin: 0px;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 160%;
                }
                h3{
                    margin: 0px;
                    font-size: 18.753px;
                    font-weight: 400;
                    line-height: 160%;
                    color: #DBE0ED;
                }
                h4{
                    margin: 0px;
                    color: #DBE0ED;
                    font-size: 21.432px;
                    font-weight: 400;
                    line-height: 160%;
                    span{
                        color: $white;
                        font-weight: 500;
                    }
                }
                .progress-bar{
                    height: 11px;
                    border-radius: 25px;
                    .p-progressbar-value{
                        background: linear-gradient(90deg, #6716CE 4.57%, #B573F6 89.96%);
                        border-radius: 25px;
                        .p-progressbar-label{
                            display: none;
                        }
                    }
                }
                .warning-msg{
                    border-radius: 10.716px;
                    border: 1.34px solid #EDD6D4;
                    background: rgba(230, 214, 212, 0.90);
                    padding: 8.037px 24.111px;
                    margin-top: 2.3125rem;
                    text-align: left;
                    p{
                        color: #C2251A;
                        font-size: 18.753px;
                        font-weight: 500;
                        line-height: 160%;
                        margin: 0px;
                    }
                    .p-image{
                        line-height: 0px;
                    }
                    &.expired{
                        h3{
                            margin: 0px;
                            color: #2A3244;
                            font-size: 18px;
                            font-weight: 500;
                            line-height: 160%;
                        }
                        h4{
                            margin: 0px;
                            color: #505A6F;
                            font-size: 16px;
                            font-weight: 400;
                            line-height: 160%;
                        }
                    }
                }
            }
        }
        .bundle-details-section{
            width: 100%;
            max-width: 1255px;
            margin: 0 auto;
            .heading-p{
                font-size: 22.96px;
                font-weight: 400;
                line-height: 160%;
                color: $white;
                margin: 0px;
                padding: 10px 0px;
            }
            .order-details-block{
                border-radius: 17.468px;
                background: #160033;
                padding: 30px;
                ul{
                    margin: 0px;
                    padding: 1.0938rem 0rem;
                    border-bottom: 1px solid #2F1E45;
                    &:first-child{
                        padding: 0rem 0rem 1.0938rem;
                    }
                    &:last-child{
                       border-bottom: 0px;
                       padding: 1.0938rem 0rem 0rem;
                    }
                    li{
                        list-style: none;
                        color: #C7CAD0;
                        font-size: 19px;
                        font-weight: 400;
                        line-height: 160%;
                        &:last-child{
                            color: $white;
                            font-weight: 500;
                            text-align: right;
                            line-height: 160%;
                        }
                        .completed{
                            border-radius: 10px;
                            background: #E1F4E2;
                            padding: 10px 15px;
                            color: #0C8F09;
                            font-size: 17px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 160%;
                        }
                        .download-button{
                            border-radius:10px;
                            border: none;
                            background: #F1F3F5;
                            padding: 12.477px 22.459px 12.477px 19.964px;
                            .p-button-label{
                                color: #242424;
                                font-size: 17.468px;
                                font-weight: 500;
                                line-height: 160%;
                            }
                            .p-button-icon{
                                color: #242424;
                                font-weight: 600;
                            }
                            &:hover{
                                background: $accent;
                                .p-button-label{
                                    color: $white;
                                }
                                .p-button-icon{
                                    color: $white;
                                }
                            }
                        }
                    }
                }
            }
            .sticky-block{
                position: sticky;
                top: 15px;
            }
        }
    }
}
.plans-table{
    .p-paginator-bottom{
        all: unset;
        margin-top: 1.5rem;
        display: flex;
        justify-content: center;
        gap: 5px;
        .p-paginator-pages .p-paginator-page{
            min-width: 2rem;
            height: 2rem;
        }
        .p-link{
            min-width: 2rem;
            height: 2rem;
        }
    }
}

@media (max-width:1200px){
    .myplans-section .plans-grid-section{
        .plans-table{
            overflow: auto;
        }
        .p-datatable-wrapper{
            min-width: 1260px;
        }
    }
}

@media (max-width:600px){
    .myplans-section{
        .plans-grid-section{
            .p-tabview-nav{
                display: block !important;
            }
        }
    }
}

@media (max-width:768px){
    .myplans-section{
        .order-details-block{
            ul{
                li:last-child{
                    width: 73%;
                    overflow-wrap: break-word;
                    font-size: 18px !important;
                }
            }
        }
    }
}

@media (max-width:960px){
    .myplans-section{
        .plans-table tr.p-datatable-emptymessage td{
            text-align: left !important;
        }
    }
}