@import '../../assets/scss/variables';


.err{
    &-msg{
        font-size: 12px;
        color: $primary;
    }
}
.flag-dropdown{
    color: $main-bg;
}
.react-tel-input input{
    padding-left:75px !important;
}
.custom-phoneno-field {
    .react-tel-input{
        font-family: $font-base;
        .form-control {
            border-radius: 8px;
            border: 1px solid rgba(226, 227, 238, 0.13);
            height: 50px;
            font-size: 16px;
            font-weight: 400;
            width: 100%;
            font-family: $font-base;
        }
        .flag-dropdown{
            border-radius: 8px 0 0 8px;
            background-color: transparent;
            border: 1px solid rgba(226, 227, 238, 0.13);
            .selected-flag{
                border-radius: 8px 0 0 8px;
                width: 51px;
                &:focus{
                    background-color: transparent;
                }
                &:hover{
                    background-color: transparent;
                }
                .arrow{
                    border-left: 5px solid transparent;
                    border-right: 5px solid transparent;
                    border-top: 5px solid $white;
                    left:25px;
                    &.up{
                        border-top: none;
                        border-bottom: 5px solid $white;
                    }
                }
            }
            &.open{
                background: none;
                z-index: 5;
                .selected-flag{
                    background: none;
                }
            }
            .country-list{
                font-family: $font-base;
                border-radius: 10px;
                width: 375px;
                padding: 0px 5px;
                max-height: 225px;
                margin: 15px 0 10px -1px;
                border: 1.687px solid rgba(211, 221, 233, 0.18);
                background: #332447;
                &::-webkit-scrollbar {
                    width: 3px;
                    border-radius: 5px;
                }
                &::-webkit-scrollbar-track {
                    background: #332447;
                    border-radius: 5px;
                    margin: 10px;
                }
                &::-webkit-scrollbar-thumb {
                    background: #504361;
                    border-radius: 5px;
                    padding: 2px;
                }
                .country{
                    padding: 10px 10px;
                    margin: 0px 10px;
                    border: 1px solid #332447;
                    span{
                        color: $white;
                    }
                    &.highlight{
                        border: 1px solid #504361;
                        background-color: #3E2E54;
                        border-radius: 8px;
                    }
                    &:hover{
                        border: 1px solid #504361;
                        background-color: #3E2E54;
                        border-radius: 8px;
                    }
                }
                .flag{
                    top: 6px;
                }
                .search{
                    background: #332447;
                    .search-emoji{
                        display: none;
                    }
                    .search-box{
                        border-radius: 8px;
                        font-size: 16px;
                        font-weight: 400;
                        padding: 11px 15px 9px 35px;
                        margin-left: 0px;
                        width: 96%;
                        border: 1.687px solid rgba(211, 221, 233, 0.18);
                        background: #332447;
                        padding-left: 38px !important;
                        color: $white;
                    }
                    &::after{
                        content: "\e908";
                        position: absolute;
                        left: 20px;
                        font-size: 16px;
                        font-family: primeicons;
                        top: 22px;
                        color: $white;
                    }
                }
                .no-entries-message{
                    color: $white;
                }
            }
        }
    }
}