
@import '../../assets/scss/variables';

.header-text-section{
    text-align: center;
    width: 90%;
    max-width: 1240px;
    margin: 0 auto;
    padding: 5rem 0rem;
    .header-text{
        color: $white;
        h1{
            font-size: 96px;
            font-weight: 700;
            line-height: 100%;
            letter-spacing: -4.8px;
            margin: 0px;
            span{
                color: $primary;
            }
        }
        p{
            font-size: 36px;
            font-weight: 400;
            letter-spacing: -1.8px;
            padding: 0rem 5rem;
        }
    }
}
.affiliate-section{
    padding: 10rem 0rem;
    position: relative;
    .affiliate-container{
        width: 90%;
        max-width: 1222px;
        margin: 0 auto;
        background: $white;
        border-radius: 30px;
        padding: 25px;
        position: relative;
        z-index: 1;
        transition: transform .2s;
        .p-image{
            img{
                width: 100%;
                max-width: 718px;
                border-radius: 30px;
            }
        }
        h2{
            margin: 0px;
            font-size: 64px;
            font-weight: 400;
            line-height: 67px;
            letter-spacing: -3.2px;
            text-align: left;
            margin-top: 5rem;
        }
        .arrow-icon{
            width: 90px;
            height: 90px;
            border-radius: 50px;
            background: $black;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            transition: all 0.3s ease-out;
            .pi{
                color: $white;
                font-size: 45px;
                transition: all 0.3s ease-out;
            }
            &:hover{
                background: $primary;
                .pi{
                    margin-left: 10px;
                    margin-top: -12px;
                }
            }
        }
        &:hover{
            transform: scale(1.03);
            cursor: pointer;
            .arrow-icon{
                background: $primary;
                .pi{
                    margin-left: 10px;
                    margin-top: -15px;
                }
            }
        }
    }
    .link-container{
        width: 90%;
        max-width: 1222px;
        margin: 1.5rem auto;
        p{
            margin: 0px;
            a{
                color: $white;
                font-size: 20px;
                font-weight: 400;
                line-height: 21px;
                letter-spacing: -1px;
                &:hover{
                    color: $primary;
                }
            }
        }
    }
    &::before{
        content: '';
        width: 365px;
        height: 365px;
        background: $primary;
        position: absolute;
        border-radius: 100%;
        left: 4rem;
        bottom: 12rem;
        z-index:0;
        filter: blur(250px);
        -webkit-filter: blur(250px);
    }
    &::after{
        content: '';
        width: 225px;
        height: 225px;
        background: $accent;
        position: absolute;
        border-radius: 100%;
        right: 10rem;
        top: 6rem;
        z-index:0;
        filter: blur(150px);
        -webkit-filter: blur(150px);
    }
}

.coverage-section{
    padding: 6rem 0rem;
    position: relative;
    .coverage-container{
        width: 90%;
        max-width: 1460px;
        margin: 0 auto;
        background: $white;
        border-radius: 30px;
        padding: 25px;
        position: relative;
        z-index: 1;
        .custom-padding{
            padding: 3rem;
        }
        .p-image{
            img{
                width: 100%;
                max-width: 690px;
            }
        }
        h2{
            margin: 0px;
            font-size: 59px;
            font-weight: 700;
            line-height: 100%;
            letter-spacing: -2.95px;
        }
        p{
            font-size: 24px;
            font-weight: 400;
            line-height: 30px;
            letter-spacing: -1.2px;
        }
        .choose-plan-button{
            background: $accent;
            border-radius: 12px;
            transition: 0.8s;
            padding: 14px 30px;
            border: none;
            margin-top: 1rem;
            .p-button-label{
                font-size: 24px;
                font-weight: 600;
                position: relative;
                z-index: 2;
                line-height: 150%;
                letter-spacing: -1.186px;
            }
            &:hover{
                background: $primary;
            }
        }
    }
    &::before{
        content: '';
        width: 365px;
        height: 365px;
        background: $primary;
        position: absolute;
        border-radius: 100%;
        left: 4rem;
        bottom: 0rem;
        z-index:0;
        filter: blur(190px);
        -webkit-filter: blur(190px);
        opacity: 0.5;
    }
    &::after{
        content: '';
        width: 225px;
        height: 225px;
        background: $accent;
        position: absolute;
        border-radius: 100%;
        right: 4rem;
        top: 0rem;
        z-index:0;
        filter: blur(190px);
        -webkit-filter: blur(190px);
    }
    &.mobile-app-section{
        .coverage-container{
            padding: 25px 25px 0px;
            .badge{
                display: inline-block;
                margin-right: 20px;
                border-radius: 10px;
                line-height: 0px;
                transition: transform .2s;
                cursor: pointer;
                img{
                    height: 56px;
                    width: auto;
                }
                &:hover{
                    background: $main-bg;
                    transform: scale(1.05);
                    img{
                        filter: contrast(0%) brightness(2); 
                    }
                }
            }
            .phone-circle-bg{
                background: url('../../assets/images/mobile-application-bg.png') no-repeat center;
                background-size: 600px;
                background-position: bottom;
                transition: .3s ease-in-out;
                overflow: hidden;
                .p-image{
                    line-height: 0px;
                    display: block;
                    img{
                        width: 100%;
                        max-width:445px;
                        border-radius: 0px;
                        margin-left: 58px;
                        transition: transform .3s;
                    }
                }
            }
            &:hover{
                .phone-circle-bg{
                    background-size: 500px;
                    .p-image{
                        img{
                            transform: scale(1.1);
                        }
                    }
                }
            }
        }
    }
}
.whoweare-section{
    padding: 8rem 0rem 10rem;
    .whoweare-container{
        width: 90%;
        max-width: 1390px;
        margin: 0 auto;
        color: $white;
        .text-block{
            padding: 0rem 6rem;
            margin-bottom: 8.5rem;
            h2{
                font-size: 48px;
                font-weight: 600;
                line-height: 120%;
                letter-spacing: -2.4px;
                span{
                    color: $primary;
                }
            }
            h3{
                font-size: 28px;
                font-weight: 500;
                line-height: 140%;
            }
        }
        .box{
            text-align: center;
            width: 100%;
            max-width: 330px;
            margin: 0 auto;
            .icon{
                width: 96px;
                height: 96px;
                background: $primary;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                margin: 0 auto;
            }
            a{
                color: $white;
            }
            h2{
                font-size: 48px;
                font-weight: 700;
                line-height: 100%;
                letter-spacing: -2.4px;
                margin: 38px 0px 28px;
            }
            p{
                font-size: 24px;
                font-weight: 400;
                line-height: 100%;
                letter-spacing: -1.2px;
                margin: 0px;
            }
        }
    }
}
.steps-section{
    padding: 6rem 0rem;
    .steps-container{
        width: 90%;
        max-width: 1590px;
        margin: 0 auto;
        color: $white;
        h2{
            font-size: 58px;
            font-weight: 400;
            line-height: 100%;
            letter-spacing: -2.9px;
            margin: 0px;
        }
        p{
            font-size: 25px;
            font-weight: 400;
            line-height: 30px;
        }
        .custom-margin{
            margin-top: 7rem;
        }
        .step-box{
            text-align: center;
            .p-image{
                position: relative;
                width: 100%;
                max-width: 445px;
                height: 355px;
                display: block;
                border-radius: 28px;
                margin: 0 auto;
                img{
                    width: 100%;
                    max-width: 445px;
                    height: 355px;
                    border-radius: 28px;
                    border: 1.525px dashed #292431;
                    background: #160033;
                }
                &:before{
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top:0px;
                    bottom:0px;
                    left:0px;
                    right:0px;
                    background: radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0.00) 0%, rgba(14, 0, 32, 0.80) 100%);
                }
            }
            .step-number{
                display: flex;
                background: #322246;
                border-radius: 50%;
                font-size: 29.416px;
                font-weight: 500;
                line-height: 73.54px;
                letter-spacing: -0.588px;
                width: 52px;
                height: 52px;
                align-items: center;
                justify-content: center;
                margin: 0 auto 4rem;
                &.center{
                    margin: 4rem auto 0rem;
                }
            }
            p{
                font-size: 25px;
                font-weight: 400;
                line-height: 125%;
                width: 100%;
                max-width: 300px;
                margin: 2rem auto 0rem;
                letter-spacing: -1.281px;
            }
        }
    }
}
.faqs-section{
    width: 90%;
    max-width: 1685px;
    margin: 0 auto;
    padding: 10rem 0rem;
    color: $white;
    h2{
        font-size: 58px;
        font-weight: 600;
        line-height: 100%;
        letter-spacing: -2.9px;
        margin: 0px;
    }
    p{
        font-size: 25px;
        font-weight: 400;
        line-height: 30px;
    }
    .custom-margin{
        margin-top: 8.5rem;
    }
    .faq-wrapper{
        width: 100%;
        max-width: 660px;
        .faq-list{
            margin-bottom: 4rem;
            position: relative;
            padding-left: 50px;
            .see-more{
                cursor: pointer;
                font-weight: 700;
                color: $accent;
            }
            .see-more:hover{
                color: $primary;
                opacity: 1;
            }
            h3{
                font-size: 28px;
                font-weight: 600;
                line-height: 39.314px;
                letter-spacing: -1.404px;
                margin: 0px 0px 12px;
            }
            p{
                font-size: 22px;
                font-weight: 400;
                line-height: 33.698px;
                letter-spacing: -1.123px;
                margin: 0px;
                a{
                    color: $white;
                    font-weight: 700;
                    &:hover{
                        color: $primary;
                    }
                }
            }
            &::before{
                content: '';
                position: absolute;
                width: 22px;
                height: 22px;
                background: $primary;
                transform: rotate(45deg);
                left: 5px;
                top: 12px;
            }
        }
        .faq-button{
            background: $accent;
            border-radius: 8px;
            transition: 0.8s;
            padding: 24px 20px;
            border: none;
            margin-left: 50px;
            width: 100%;
            max-width: 600px;
            .p-button-label{
                font-size: 23px;
                font-weight: 600;
                position: relative;
                z-index: 2;
                line-height: 24px;
            }
            &:hover{
                background: $primary;
            }
        }
    }
    .faq-image{
        display: block;
        line-height: 0px;
        img{
            width: 100%;
            max-width: 715px;
            border-radius: 42px;
        }
    }
}
.get-in-touch-section{
    width: 90%;
    max-width: 1685px;
    margin: 0 auto;
    padding: 4rem 0rem 8rem;
    color: $white;
    h2{
        font-size: 58px;
        font-weight: 600;
        line-height: 100%;
        letter-spacing: -2.9px;
        margin: 0px;
    }
    p{
        font-size: 25px;
        font-weight: 400;
        line-height: 30px;
    }
    .custom-margin{
        margin-top: 10rem;
        .form-wrapper{
            width: 100%;
            max-width: 570px;
            margin: 0 auto;
            .input-field{
                margin-bottom: 0.875rem;
                label{
                    font-size: 20px;
                    font-weight: 500;
                    line-height: 20px;
                }
                .p-inputtext{
                    border-radius: 8px;
                    border: 1.482px solid rgba(226, 227, 238, 0.13);
                    background: rgba(22, 0, 51, 0.10);
                    padding: 16px 24px;
                    color: $white;
                    font-family: $font-base;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 150%;
                    width: 100%;
                }
                .p-inputtextarea{
                    resize: none;
                    min-height: 145px;
                }
            }
            .p-checkbox{
                position: relative;
                top: -3px;
                .p-checkbox-box{
                    background: $purple-dark;
                    border-radius: 5px;
                    width: 26px;
                    height: 26px;
                    min-width: 26px;
                    &:hover{
                        border: 2px solid $accent;
                    }
                    .p-checkbox-icon.p-icon{
                        width: 16px;
                        height: 16px;
                    }
                }
                &.p-highlight{
                    .p-checkbox-box{
                        border: 2px solid $accent; 
                    }
                }
            }
            .send-button{
                background: $accent;
                border-radius: 12px;
                transition: 0.8s;
                padding: 18px 40px;
                border: none;
                margin-top: 0.25rem;
                .p-button-label{
                    font-size: 22px;
                    font-weight: 600;
                    position: relative;
                    z-index: 2;
                    line-height: 35.567px;
                }
                &:hover{
                    background: $primary;
                }
            }
            .agree-label{
                a{
                    color: $primary;
                    &:hover{
                        color: $accent;
                        text-decoration: underline;
                    }
                }
            }
        }
    }
    .get-in-touch-image{
        line-height: 0px;
        img{
            width: 100%;
            max-width: 786px;
        }
    }
    .info-box{
        text-align: center;
        width: 100%;
        max-width: 385px;
        margin: 0 auto;
        h3{
            font-size: 36px;
            font-weight: 600;
            line-height: 30px;
        }
        p{
            font-size: 22px;
            font-weight: 400;
            line-height: 30px;
            min-height: 90px;
            opacity: 0.7;
        }
        a{
            color: $white;
            font-size: 30px;
            font-weight: 600;
            line-height: 30px;
            padding-bottom: 5px;
            .p-image{
                img{
                    position: relative;
                    top: 5px;
                    margin-right: 10px;
                    width: 34px;
                }
            }
            &:hover{
                color: $primary;
                border-bottom: 1px solid $primary;
                .p-image{
                    img{
                        filter: brightness(60%) sepia(100) saturate(100) hue-rotate(345deg);
                    }
                }
            }
        }
    }
}

// Responsive CSS

@media (min-width:1200px) and (max-width:1400px){
    .header-text-section{
        .header-text{
            h1{
                font-size: 85px;
            }
            p{
                font-size: 32px;
            }
        }
    }
    .coverage-section{
        .coverage-container{
            h2{
                font-size: 50px;
            }
            p{
                font-size: 20px;
            }
        }
        &.mobile-app-section{
            .coverage-container{
                .phone-circle-bg{
                    background-size: 500px;
                    .p-image img{
                        max-width: 400px;
                    }
                }
            }
        }
    }
    .whoweare-section .whoweare-container .text-block h2{
        font-size: 42px;
    }
}
@media (max-width:991px){
    .header-text-section{
        padding: 1rem 0rem;
        .header-text{
            h1{
                font-size: 56px;
                line-height: 110%;
                letter-spacing: -2.8px;
            }
            p{
                font-size: 18px;
                padding: 0rem 3rem;
            }
        }
    }
    .coverage-section {
        padding: 0rem;
        .coverage-container{
            .custom-padding{
                padding: 0px;
            }
            h2{
                font-size: 38px;
            }
            p{
                font-size: 18px; 
            }
        }
        &.mobile-app-section .coverage-container{
            .badge{
                margin-bottom: 20px;
            }
            .phone-circle-bg{
                background-size: 330px;
                .p-image img{
                    margin-left: 35px;
                    max-width: 290px;
                }
            }
        }
    }
    .whoweare-section{
        padding: 3rem 0rem 0rem;
        .whoweare-container {
            .text-block{
                padding: 0rem;
                margin-bottom: 2rem;
                h3{
                    font-size: 20px;
                }
                h2{
                    font-size: 30px;
                    line-height: 150%;
                }
            }
            .box{
                margin-bottom: 3.5rem;
                h2{
                    font-size: 32px;
                }
                p{
                    font-size: 18px;
                    line-height: 30px; 
                }
                .icon{
                    width: 65px;
                    height: 65px;
                    .p-image{
                        img{
                            width: 28px;
                        }
                    }
                }
            }
        }
    }
    .steps-section{
        padding: 1rem 0rem 5rem;
        .steps-container{
            .custom-margin{
                margin-top: 0rem;
            }
            h2{
                font-size: 42px;
            }
            p{
                font-size: 18px;
            }
            .step-box{
                p{
                    font-size: 18px;
                }
                &.middle{
                    display: flex;
                    flex-flow: column;
                    .p-image{
                        order: 2;
                    }
                    p{
                        order: 3;  
                    }
                }
                .step-number{
                    margin: 2rem auto 2rem;
                    &.center{
                        margin: 2rem auto 2rem;
                    }
                }
            }
        }
    }
    .faqs-section{
        padding: 6rem 0rem 3rem;
        h2{
            font-size: 42px;
            font-weight: 400;
            line-height: 100%;
        }
        p{
            font-size: 18px;
            font-weight: 400;
            line-height: 30px; 
        }
        .custom-margin{
            margin-top: 4.5rem;
        }
        .faq-wrapper{
            .faq-list{
                padding-left: 0px;
                margin-bottom: 3rem;
                h3{
                    font-size: 26px;
                }
                p{
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 30px; 
                }
                &::before{
                    position: static;
                    display: block;
                    margin-bottom: 20px;
                }
            }
            .faq-button{
                margin-left: 0px !important;
            }
        }
        .faq-image{
            margin-top: 2rem;
        }
    }
    .get-in-touch-section{
        padding: 4rem 0rem 3rem;
        h2{
            font-size: 42px;
            font-weight: 400;
        }
        p{
            font-size: 18px;
            min-height: auto !important;
        }
        .form-block{
            margin-top: 3rem;
            display: flex;
            flex-direction: column-reverse;
        }
        .get-in-touch-image{
            margin-top: 3rem;
            display: block;
            position: relative;
            z-index: 1;
        }
        .custom-margin{
            margin-top: 2rem;
        }
        .info-box{
            h3{
                font-size: 30px;
            }
            p{
                font-size: 18px;
                br{
                    display: none;
                }
            }
            a{
                font-size: 20px;
            }
        }
    }
}
@media (min-width:992px) and (max-width:1199px){
    .header-text-section{
        .header-text{
            h1{
                font-size: 60px;
            }
            p{
                font-size: 24px;
            }
        }
    }
    .whoweare-section{
        .whoweare-container{
            .text-block{
                h2{
                    font-size: 36px;
                }
            }
            .box{
                h2{
                    font-size: 30px;
                }
                p{
                    font-size: 18px;
                }
            }
        }
    } 
    .coverage-section{
        .coverage-container{
            .custom-padding {
                padding: 1rem;
            }
            h2{
                font-size: 45px;
            }
            p{
                font-size: 18px;
            }
        }
        &.mobile-app-section{
            .coverage-container{
                .phone-circle-bg{
                    background-size: 375px;
                    .p-image img{
                        max-width: 300px;
                        margin-left: 45px;
                    }
                }
                .badge img{
                    height: 45px;
                }
            }
        }
    }
    .steps-section{
        .steps-container{
            .step-box {
                .p-image{
                    height: auto;
                    img{
                        height: auto;
                    }
                }
                .step-number{
                    margin: 0 auto 2rem;
                    &.center{
                        margin: 2rem auto 0rem;
                    }
                }
                p{
                    font-size: 18px;
                    line-height: 140%;
                }
            }
        }
    }
    .get-in-touch-section{
        .info-box{
            a{
                font-size: 20px;
            }
            p{
                font-size: 18px;
                line-height: 25px;
            }
        }
    }
}

@media (max-width: 620px) {
    .faqs-section{
        padding: 5rem 0rem 3rem !important;
        .custom-margin {
            margin-top: 2rem;
        }
    }
    .affiliate-section .affiliate-container .p-image img{
        border-radius: 12px;
    }
    .get-in-touch-section .info-box a .p-image img{
        width: 28px;
    }
    .affiliate-section::before{
        width: 300px;
        height: 300px;
    }
    .footer-section .scroll-to-top{
        right: 1rem;
    }
    .footer-section{
        padding: 50px 0px 50px 0px;
        .copyrights-section p{
            font-size: 14px;
        }
    }
    .coverage-section .coverage-container {
        .p-image img{
            margin-top: 1rem;
        }
        .badge{
            img{
                margin-top: 0rem;
            }
        }
    }
    .logout-dialog{
        padding: 2rem;
        .p-dialog-header{
            right: 35px;
            top: 38px;
        }
        .p-dialog-content{
            h2{
                font-size: 24px;
            }
            p{
                font-size: 22px;
            }
            .logout-buttons{
                .p-button-label{
                    font-size: 18px;
                    line-height: 110%;
                }
            }
            .logout-icon{
                width: 50px;
                height: 50px;
                img{
                    width: 25px;
                }
            }
        }
    }
}

@media (max-width:430px){
    .coverage-section::before{
        width: 165px;
        height: 165px;
    }
    .coverage-section {
        &.mobile-app-section .coverage-container{
            .phone-circle-bg{
                background-size: 280px;
                .p-image img{
                    margin-left: 30px;
                    max-width: 240px;
                }
            }
        }
    }
}