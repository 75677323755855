@import '../../assets/scss/variables';

.getintouch-section{
    position: relative;
    background: $main-bg;
    .container{
        width: 90%;
        max-width: 1120px;
        margin: 0 auto;
        color: $white;
        height: 100%;
        min-height: calc(100vh - 196px); 
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 1;
        padding-bottom: 10rem;
        h1{
            font-size: 128px;
            font-weight: 600;
            line-height: 100%;
            letter-spacing: -6.4px;
            margin: 0px;
        }
        h2{
            font-size: 86px;
            letter-spacing: -6.4px;
            margin: 0px;
            font-weight: 600;
            span{
                color: $primary;
            }
        }
        .form-block{
            width: 90%;
            max-width: 600px;
            margin: 80px auto 55px;
            .p-inputtext,.p-dropdown{
                background: none;
                border: none;
                color: $white;
                font-size: 36px;
                font-weight: 600;
                line-height: 100%;
                letter-spacing: -1.3px;
                border-radius: 0px;
                border-bottom: 1px solid $primary;
                text-align: center;
                &:focus, &.p-focus{
                    box-shadow: none;
                    outline: 0 none;
                }
            }
            .p-dropdown-label.p-inputtext{
                border-bottom: 0;
            }
            .p-placeholder,.p-inputtext::placeholder{
                opacity: 0.3;
                color: white;
            }
            h3{
                font-size: 36px;
                font-weight: 500;
                line-height: 100%;
                letter-spacing: -1.8px;
            }
        }
        .goto-button{
            background: $accent;
            border-radius:30px;
            padding: 20px 60px;
            border: none;
            margin-top: 2rem;
            .p-button-label{
                font-size: 40px;
                font-weight: 700;
                position: relative;
                z-index: 2;
                color: $white;
                line-height: 103%;
                letter-spacing: -2px;

            }
            &:hover{
                background: $primary;
            }
        }
        .custom-margin{
            margin-top: 2rem;
        }
    }
    &::before{
        content: '';
        width: 1000px;
        height: 700px;
        background: $blue-bg;
        position: absolute;
        border-radius: 50%;
        left: -7rem;
        top: -15rem;
        z-index:0;
        filter: blur(75px);
        -webkit-filter: blur(75px);
        transform: rotate(45deg);
    }
    &::after{
        content: '';
        width: 500px;
        height: 350px;
        background: $blue-bg;
        position: absolute;
        border-radius: 50%;
        right: 0rem;
        bottom: 0rem;
        z-index:0;
        filter: blur(75px);
        -webkit-filter: blur(75px);
    }
    &.questions-section{
        .container{
            max-width: 1400px;
            .custom-margin{
                margin-top: 2rem;
            }
            .form-block{
                margin: 80px 0px 55px;
            }
        }
    } 
}


// Responsive CSS

@media (max-width:991px){
    .getintouch-section{
        padding: 0px 1.5rem;
        .container{
            display: block;
            padding-top: 3rem;
            min-height: calc(100vh - 119px);
            h1{
                font-size: 46px;
                line-height: 120%;
                letter-spacing: -2.3px;
            }
            h2{
                font-size: 46px;
                line-height: 120%;
                letter-spacing: -2.3px;
            }
            .form-block{
                .p-inputtext{
                    font-size: 22px;
                    line-height: 100%;
                    letter-spacing: -1.1px;
                }
                h3{
                    font-size: 26px;
                }
            } 
            .goto-button{
                border-radius: 18px;
                padding: 19px 65px;
                .p-button-label{
                    font-size: 18px;
                    line-height: 104%;
                    letter-spacing: -0.9px;
                }
            }
        }
        &::before{
            width: 250px;
            left: 5rem;
            transform: rotate(0deg);
            height: 450px;
        }
        &::after{
            width: 250px;
            right: 5rem;
        }
    }
}
@media (min-width:992px) and (max-width:1199px){
    .getintouch-section{
        .container h1, .container h2{
            font-size: 70px;
            letter-spacing: -3px;
        }
        &::before{
            height: 600px;
        }
    }
}
@media (min-width:1200px) and (max-width:1500px){
    .getintouch-section .container h1{
        font-size: 90px;
        line-height: 98px;
    }
}