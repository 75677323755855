@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import '../scss/variables';

// **** Prime React CSS **** //
@import 'primereact/resources/themes/lara-light-indigo/theme.css';
@import 'primereact/resources/primereact.min.css';
@import 'primeicons/primeicons.css';
@import 'primeflex/primeflex.scss';

// **** Global CSS **** //

body {
    margin: 0px;
    padding: 0px;
    font-family: $font-base;
    overflow-x: hidden;
    &::-webkit-scrollbar {
        width: 5px;
    }
    &::-webkit-scrollbar-track {
        background: $black;
    }
    &::-webkit-scrollbar-thumb {
        background: $primary;
        padding: 2px;
    }
}
a {
    text-decoration: none;
    &:hover {
        text-decoration: none;
    }
}
.p-component{
    font-family: $font-base;
}
.voucher{
    border-radius: 10px;
    border: 1px solid #301e4c;
    padding: 15px 15px;
    color: $black;
    .icon{
        border-radius: 12px;
        background: $accent;
        display: flex;
        width: 65px;
        height: 65px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .pi{
            color: $white;
            font-size: 30px;
        }
    }
    .text{
        text-align: left;
        h2{
            margin: 0px;
            font-size: 20px;
            color: $white;
            line-height: 140%;
            font-weight: 500;
        }
    }
    .pi-arrow-right{
        color: $white;
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.5s ease-out;
        font-size: 20px;
    }
    &:hover{
        background: $primary;
        cursor: pointer;
        .icon{
            background: $white;
            .pi{
                color: $primary;
            }
        }
        .text{
            h2{
                color: $white !important;
            }
        }
        .pi-arrow-right{
            transform: rotate(-30deg);
            color: $white;
        }
    }
}
.voucher-disable{
    border-radius: 10px;
    border: 1px solid #301e4c;
    padding: 15px 15px;
    color: $black;
    .icon{
        border-radius: 12px;
        background: $accent;
        display: flex;
        width: 65px;
        height: 65px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .pi{
            color: $white;
            font-size: 30px;
        }
    }
    .text{
        text-align: left;
        h2{
            margin: 0px;
            font-size: 20px;
            color: $white;
            line-height: 140%;
            font-weight: 500;
        }
    }
    .pi-arrow-right{
        color: $white;
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.5s ease-out;
        font-size: 20px;
    }
    &:hover{
        cursor: not-allowed;
    }
}

.main-section{
    padding: 0px;
    background-color: $main-bg;
    .header-menu-section{
        padding: 50px 70px;
        position: relative;
        z-index: 1;
        .p-menubar{
            background: none;
            border: none;
            padding: 0rem;
            .p-menubar-start{
                margin-right: 35px;
            }
            .p-menubar-root-list{
                gap: 15px;
                background:none;
                .p-menuitem{
                    .p-menuitem-content{
                        padding: 8px 14px;
                        border-radius: 6px;
                        .p-menuitem-link{
                            padding: 0px;
                            .p-menuitem-text{
                                font-size: 18px;
                                font-weight: 500;
                                line-height: 140%;
                                color: $white;
                            }
                        }
                        &:hover{
                            background: $accent;
                        }
                    }
                    &.p-focus{
                        box-shadow: none;
                        outline: none;
                        background: none;
                        .p-menuitem-content{
                            background: none;
                        }
                    }
                }
            }
        }
        .header-right{
            .language-dropdown{
                background: transparent;
                border: none;
                .p-dropdown-label{
                    padding: 0px;
                    font-size: 18px;
                    font-weight: 400;
                    letter-spacing: -0.9px;
                    color: $white;
                }
                &.p-focus{
                    box-shadow: none;
                    border: none;
                }
                .p-dropdown-trigger{
                    color: $white;
                }
            }
            .login-button{
                background: $accent;
                border-radius: 8px;
                transition: transform .2s;
                padding: 18px 32px;
                border: none;
                .p-button-label{
                    font-size: 20px;
                    font-weight: 600;
                    position: relative;
                    z-index: 2;
                    line-height: 24px;
                }
                &:hover{
                    background: $primary;
                    transform: scale(1.1);
                }
            }
            .my-account-buttons{
                width: 60px;
                height: 60px;
                background: rgba(239, 240, 245, 0.19);
                border-radius: 2rem;
                border: none;
                margin: 0rem 0.75rem;
                display: flex;
                align-items: center;
                justify-content: center;
                &.user-button{
                    background: $white;
                }
                &:focus{
                    box-shadow: none;
                }
            }
        }
    }
    .loading-div{
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 40px 0px;
        color:$white;
        min-height: calc(100vh - 452px + 196px); 
    }
}
.p-dropdown-panel{
    margin-top: 1.5rem;
    border-radius: 15px;
    border: 1.687px solid rgba(211, 221, 233, 0.18);
    background: #332447;
    .p-dropdown-items-wrapper{
        border-radius: 10px;
        max-height: 295px !important;
        .p-dropdown-items{
            margin: 0px;
            padding: 10px;
            .p-dropdown-item{
                background: #332447;
                border: 1px solid #332447;
                font-size: 15px;
                padding: 0.65rem 1rem;
                color: $white;
                font-size: 20px;
                font-weight: 500;
                line-height: 160%;
                &.p-highlight{
                    border-radius: 8px;
                    border: 1px solid #504361;
                    background: #3E2E54;
                }
                &:hover{
                    border-radius: 8px;
                    border: 1px solid #504361;
                    background: #3E2E54;
                }
            }
        }
        &::-webkit-scrollbar {
            width: 4px;
            border-radius: 6px;
        }
        &::-webkit-scrollbar-track {
            background: transparent;
            border-radius: 6px;
        }
        &::-webkit-scrollbar-thumb {
            background: #574C65;
            padding: 2px;
            border-radius: 6px;
        }
    }
    &.language-panel{
        margin-left: -5rem;
    }
}
.p-menu-overlay{
    margin-top: 1.5rem;
    border-radius: 15px;
    border: 1.687px solid rgba(211, 221, 233, 0.18);
    background: #332447;
    padding: 0rem;
    width: auto;
    .p-menu-list{
        padding: 1rem;
        .p-menuitem{
            .p-menuitem-content{
                border: 1px solid #332447;
                .p-menuitem-link{
                    padding: 10px 14px;
                    .p-menuitem-text{
                        font-size: 20px;
                        font-weight: 500;
                        line-height: 160%;
                        color: $white;
                        font-family: $font-base;
                    }
                    .p-image{
                        margin-right: 12px;
                        line-height: 0;
                        img{
                            width: 26px;
                        }
                    }
                }
            }
            &.p-focus{
                .p-menuitem-content{
                    border: 1px solid transparent;
                    background: none;
                } 
            }
            &.p-menuitem-active{
                .p-menuitem-content{
                    border-radius: 8px;
                    border: 1px solid #504361;
                    background: #3E2E54;
                }  
            }
            &:hover{
                .p-menuitem-content{
                    border-radius: 8px;
                    border: 1px solid #504361;
                    background: #3E2E54;
                } 
            }
        }
    }
}
.logout-dialog{
    width: 90%;
    max-width: 565px;
    padding: 3.25rem;
    border-radius: 28.204px;
    background: #160033;
    .p-dialog-header{
        padding: 0rem;
        position: absolute;
        background: none;
        right: 52px;
        top: 68px;
        border-radius: 20px;
        .p-dialog-header-icons{
            padding: 4px;
            border-radius: 20px;
            &:hover{
                background: $white;
            }
            .p-link{
                svg.p-icon{
                    width: 30px;
                    height: 30px;
                }
               &:hover{
                background: none;
               }
            }
        }
    }
    .p-dialog-content{
        padding: 0rem;
        background: transparent;
        color: $white;
        h2{
            margin: 0px;
            font-size: 28px;
            font-weight: 500;
            line-height: 150%;
        }
        .logout-icon{
            border-radius: 12px;
            background: #44345A;
            width: 65px;
            height: 65px;
            display: flex;
            align-items: center;
            justify-content: center;
            .p-image{
                line-height: 0px;
            }
            .pi{
                font-size: 22px;
            }
        }
        p{
            margin: 0px;
            font-size: 28px;
            font-weight: 500;
            line-height: 150%;
            padding: 2.245rem 0rem;
            &.small{
                font-size: 14px;
                font-weight: 500;
                line-height: 21px;
                text-align: left;
                padding: 0px 0px 2rem;
            }
            &.delete-success{
                color: #F73859;
                font-size: 18px;
                text-align: center;
                padding: 1.5rem 0rem 0.5rem;
            }
        }
        .logout-buttons{
            border-radius: 11px;
            background: #6A459A;
            border: 2px solid #6B4C93;
            width: 100%;
            .p-button-label{
                font-size: 19px;
                font-weight: 600;
                line-height: 32.639px;
                color: $white;
            }
            &.cancel{
                background: #160033;
                &:hover{
                    background: $primary;
                    border: 2px solid $primary;
                    .p-button-label{
                        color: $white;
                    }
                }
            }
            &:focus{
                box-shadow: none;
            }
            &:hover{
                background: $white;
                border: 2px solid $white;
                .p-button-label{
                    color: #6A459A;
                }
            }
        }
        .delete-button{
            width: 100%;
            background: #F73859;
            border: none;
            .p-button-label{
                font-size: 21px;
                font-weight: 600;
                line-height: 32.639px;
                color: $white;
            }
            &:focus{
                box-shadow: none;
                outline: none;
            }
        }
    }
}
.QRcode-dialog{
    width: 90%;
    max-width: 500px;
    padding: 2rem;
    border-radius: 20.74px;
    background: #160033;
    .p-dialog-header{
        padding: 0rem;
        background: none;
        border-radius: 20px;
        .p-dialog-header-icons{
            padding: 4px;
            margin-bottom: 20px;
            border-radius: 20px;
            &:hover{
                background: $white;
            }
            .p-link{
                svg.p-icon{
                    width: 30px;
                    height: 30px;
                }
               &:hover{
                background: none;
               }
            }
        }
    }
    .p-dialog-content{
        padding: 0rem;
        background: transparent;
        color: $white;
        text-align: center;
        .scan-text{
            margin-top: 20px;
        }
    }
}
.eSIMActivation-dialog{
    width: 100%;
    max-width: 500px;
    padding: 2rem;
    border-radius: 20.74px;
    background: #160033;
    .add-bundle-button{
        padding: 10px 22.5px;
        border-radius: 8px;
        border: none;
        background:$white;
        .p-button-label{
            color: $purple-dark;
            font-size: 19.688px;
            font-weight: 500;
            line-height: 160%;
        }
        &:hover{
            background: $accent;
            .p-button-label{
                color: $white;
            }
        }
    }
    .p-dialog-header{
        padding: 0rem;
        background: none;
        border-radius: 20px;
        .p-dialog-header-icons{
            padding: 4px;
            margin-bottom: 20px;
            border-radius: 20px;
            &:hover{
                background: $white;
            }
            .p-link{
                svg.p-icon{
                    width: 30px;
                    height: 30px;
                }
               &:hover{
                background: none;
               }
            }
        }
    }
    .p-dialog-content{
        padding: 0rem;
        background: transparent;
        color: $white;
        margin-top: -20px;
        padding: 2px;
        text-align: center;
        .esim-popup-title{
            font-size: 20px;
            font-weight: 900;
        }
        .esim-popup-subtitle{
            font-weight: 400;
            font-size: 15px;
            text-align: center;
            line-height: 1.3;
        }
        
    }
}
.button{
    border-radius: 11px;
    padding: 20px;
    font-size: 24px;
    &-primary{
        background-color: $btn-bg;
        border-color: $btn-bg;
    }
    &-secondary{
        background-color: $main-bg;
        border: 2px solid $btn-bg;
    }
}
.icon-blk{
    background-color: $icon-bg;
    height: 35px;
    width: 35px;
    align-items: center;
    justify-content: center;
    display: flex;
    color: white;
    border-radius: 35px;
}
.footer-section{
    border-top: 1px solid #3F3250;
    background: #0E0020;
    padding: 84px 0px 56px 0px;
    .footer-info{
        width: 90%;
        max-width: 1340px;
        margin: 0 auto;
        h3{
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            color: rgba(255, 255, 255, 0.73);
            margin: 0rem 0rem 1rem;
            letter-spacing: -0.9px;
        }
        p{
            color: $white;
            a{
                color: $white;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: -1px;
                &:hover{
                    color: $primary;
                    .whatsapp-icon{
                        img{
                            filter: brightness(60%) sepia(100) saturate(100) hue-rotate(345deg);
                        }
                    }
                }
                &.custom-fontSize{
                    font-size: 23px;
                    letter-spacing: -1.2px;
                }
                .whatsapp-icon{
                    line-height: 0px;
                    img{
                        width: 17px;
                        position: relative;
                        top: 2px;
                        margin-right: 5px;
                    }
                }
            }
        }
        .footer-button {
            cursor: pointer; 
            color: $white; 
            transition: color 0.2s ease; 
            letter-spacing: -1px;
            &:hover {
              color: $primary;
            }
        }
        .p-image{
            line-height: 0px;
        }
    }
    .copyrights-section{
        width: 90%;
        max-width: 1716px;
        margin: 4rem auto 0rem;
        border-top: 1px solid #3F3250;
        padding-top: 3.5rem;
        p{
            font-size: 18px;
            font-weight: 300;
            color: $white;
            line-height: 145%;
            margin: 0px;
            a{
                text-decoration-line: underline;
                color: $white;
                &:hover{
                    color: $primary;
                }
            }
        }
        .social-icons{
            a{
                position: relative;
                transition: all 0.2s ease-in-out;
                top: 0px;
                &:hover{
                    opacity: 0.7;
                    top: -6px;
                }
            }
        }
    }
    .scroll-to-top{
        position: fixed;
        right: 3rem;
        bottom: 7.8rem;
        background: $primary;
        animation: moveUpDown 3s infinite;
        border-radius: 8px 0px;
        padding: 10px;
        width: auto;
        height: auto;
        .pi{
            font-size: 12px;
            color: $white;
        }
        &:hover{
            background: $accent;
            animation-play-state: paused;
            border-radius: 0px 8px;
        }
    }
}
.header-left{
    .logo-img{
        img{
            height: 75px;
        }
    }
}

@keyframes moveUpDown {
    0% {
       bottom: 7.8rem;
    }
    50% {
        bottom: 10rem;
    }
    100% {
        bottom: 7.8rem;
    }
}

.p-toast{
    opacity: 1;
    z-index: 9999 !important;
    max-width: 26rem;
    .p-toast-message{
        &.p-toast-message-success{
            background:$white;
            color: $black;
            border: none;
            position: relative;
            &::before{
                content: "";
                width: 4px;
                height: 80%;
                position: absolute;
                left: 8px;
                top: 8px;
                background: #2BA079;
                border-radius: 2px;
            }
        }
        &.p-toast-message-error{
            background:$white;
            color: $black;
            border: none;
            position: relative;
            &::before{
                content: "";
                width: 4px;
                height: 80%;
                position: absolute;
                left: 8px;
                top: 8px;
                background: #ff5757;
                border-radius: 2px;
            }
        }
        &.p-toast-message-warn{
            background:$white;
            color: $black;
            border: none;
            position: relative;
            &::before{
                content: "";
                width: 4px;
                height: 80%;
                position: absolute;
                left: 8px;
                top: 8px;
                background: #cc8925;
                border-radius: 2px;
            }
        }
        &.p-toast-message-info{
            background:$white;
            color: $black;
            border: none;
            position: relative;
            &::before{
                content: "";
                width: 4px;
                height: 80%;
                position: absolute;
                left: 8px;
                top: 8px;
                background: #5774cd;
                border-radius: 2px;
            }
            .p-toast-message-icon{
                background: #5774cd !important;
            }
        }
        .p-toast-message-content{
            padding: 12px;
            padding-left: 25px;
            align-items: center;
            .p-toast-message-text{
                .p-toast-summary{
                    font-size: 14px;
                    font-weight: 500;
                }
                .p-toast-detail{
                    font-size: 15px;
                    margin: 0.4rem 0 0 0;
                    color: #505050;

                    &:first-letter {
                        text-transform: uppercase;
                    }
                }
            }
        }
        .p-toast-icon-close{
            width: auto;
            height: auto;
            color: #505050 !important;
            font-size: 14px;
            position: absolute;
            top: 10px;
            right: 10px;
            z-index: 9;
            .p-toast-icon-close-icon{
                font-size: 13px;
            }
        }
    }
}
.btn-disable {
    opacity: 0.5;
    pointer-events: none;
}
.show-on-mobile{
    display: none;
}
.view-button {
    height: 75px;
    --border-angle: 0turn;
    --main-bg: conic-gradient(from var(--border-angle),#0E0020,#0E0020 5%,#0E0020 60%,#0E0020 95%);
    border: solid 3px transparent;
    border-radius: 30px;
    --gradient-border: conic-gradient(from var(--border-angle), transparent 25%, #BA38F7, #232191 99%, transparent);
    background: var(--main-bg) padding-box, var(--gradient-border) border-box, var(--main-bg) border-box;
    background-position: center center;
    animation: bg-spin 2s linear infinite;
    box-shadow: 0px 0px 15px $blue-bg;
    padding: 0rem 3.6rem;
    .p-button-label{
        font-size: 24px;
        font-weight: 400;
        letter-spacing: -1.2px;
        position: relative;
        z-index: 2;
    }
    &:hover {
        background: linear-gradient(to right, #BA38F7, #232191) border-box;
    }
}
@keyframes bg-spin {
    to {
      --border-angle: 1turn;
    }

}
@property --border-angle {
    syntax: "<angle>";
    inherits: true;
    initial-value: 0turn;
}

// Responsive CSS

@media (max-width:991px){
    .main-section{
        position: relative;
        z-index: 2;
        .header-menu-section{
            padding: 20px 15px;
            .p-menubar {
                position: relative;
                .p-menubar-root-list{
                    gap: 10px !important;
                    .p-menuitem-text{
                        font-size: 16px !important; 
                    }
                    .p-menuitem .p-menuitem-content{
                        padding: 8px 10px;
                    }
                }
                .p-menubar-start{
                    margin-right: 15px;
                }
            }
            .header-left{
                .logo-img{
                    img{
                        height: 45px;
                    }
                }
            }
            .header-right{
                margin-right: 3.6rem;
                gap: 0px !important;
                .login-button{
                    padding: 12px 28px;
                    .p-button-label{
                        font-size: 16px;
                    }
                }
                .my-account-buttons{
                    margin: 0rem 1rem 0rem 0rem;
                    width: 50px;
                    height: 50px;
                }
                .pi-chevron-down{
                    display: none;
                }
                &.affiliate-header-right{
                    margin-right: 0rem;
                }
            }
            .p-menubar-button{
                border-radius: 8px;
                background: #251B32;
                width: 48px;
                height: 48px;
                position: absolute;
                right: 0;
                svg{
                   display: none;
                }
                &::before{
                    content: "\e91d";
                    font-family: primeicons;
                    font-size: 24px;
                    color: $white;
                }
                &:focus{
                    box-shadow: none;
                }
            }
            .p-menubar-mobile-active{
                .p-menubar-button{
                    &::before{
                        content: "\e90b";
                    }
                }
            }
        }
    } 
    .footer-section{
        text-align: center;
        .footer-info{
            h3{
                margin-top: 1.2rem;
            }
            .payment-accept{
                justify-content: center;
            }
        }
        .copyrights-section{
            .mobile-flex{
                flex-direction: column !important;
                .social-icons{
                    justify-content: center;
                    margin-top: 2rem;
                }
            }
        }
    }
    .p-dropdown-panel.language-panel {
        margin-left: 0rem; 
    }
}

@media (min-width:769px) and (max-width:991px){
    .footer-section .footer-info{
        .grid{
            .order-1{
                order:1;
                margin-bottom: 2rem;
            }
            .order-2{
                order:3;
            }
            .order-3{
                order:4;
            }
            .order-4{
                order:2;
            }
        }
    }
}
@media (min-width:1300px) and (max-width:1795px){
    .header-left .logo-img img {
        height: 55px;
    }
    .main-section .header-menu-section{
        padding: 40px 28px;
        .p-menuitem-link .p-menuitem-text{
            font-size: 16px !important;
        }
        .header-right .login-button{
            padding: 18px 12px;
            .p-button-label{
                font-size: 16px;
            }
        }
        .p-menubar .p-menubar-root-list{
            gap: 5px;
            .p-menuitem .p-menuitem-content{
                padding: 8px 10px;
            }
        }
    }
    .faqs-section .faq-wrapper {
        max-width: 530px;
       .faq-list h3{
            font-size: 26px;
       }
       .faq-list p{
            font-size: 20px;
       }
       .faq-button{
            max-width: 500px !important;
       }
    }
}

@media (min-width:1200px) and (max-width:1350px){
    .header-left .logo-img img {
        height: 45px;
    }
    .main-section .header-menu-section{
        padding: 30px 20px;
        .p-menuitem-link .p-menuitem-text{
            font-size: 15px !important;
        }
        .header-right .login-button{
            padding: 18px 15px;
            .p-button-label{
                font-size: 15px;
            }
        }
       .header-right .language-dropdown .p-dropdown-label{
            font-size: 15px;
       }
        .p-menubar .p-menubar-root-list{
            gap: 5px;
            .p-menuitem .p-menuitem-content{
                padding: 8px 8px;
            }
        }
    }
    .faqs-section .faq-wrapper {
        max-width: 530px;
       .faq-list h3{
            font-size: 26px;
       }
       .faq-list p{
            font-size: 20px;
       }
       .faq-button{
            max-width: 480px !important;
       }
    }
}

@media (max-width:991px){
    .main-section{
        .header-menu-section{
            .p-menubar {
                &.p-menubar-mobile-active{
                    .p-menubar-root-list{
                        background: $main-bg;
                        border-radius: 10px;
                        margin-top: 1rem;
                        height: 83vh;
                        .p-menuitem{
                            .p-menuitem-content{
                                padding: 16px 0px 22px;
                                border-bottom: 1px solid #342942;
                                margin: 0px 30px;
                                border-radius: 0px;
                            }
                            &:last-child{
                                .p-menuitem-content{
                                    border: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .view-button {
        height: 58px;
        padding: 0rem 1rem;
        .p-button-label {
            font-size: 18px;
        }
    }
}

@media (min-width:961px) and (max-width:1199px){
    .hide-on-mobile{
        display: none;
    }
    .show-on-mobile{
        display: block;
    }
    .main-section .header-menu-section{
        .header-right{
            margin-right: 0rem;
            gap: 1px !important;
            .login-button{
                padding: 12px 15px;
                margin-left: 5px;
                .p-button-label{
                    font-size: 16px;
                }
            }
            .language-dropdown .p-dropdown-label{
                font-size: 16px;
            }
        }
    }
    .main-section{
        .header-menu-section{
            padding: 30px 25px;
            .p-menuitem .p-menuitem-content{
                padding: 8px 10px !important;
                .p-menuitem-text{
                    font-size: 16px !important;
                }
            }
            .header-left .logo-img img{
                height: 55px;
            }
        }
    }
}

@media (max-width:450px){
    .main-section{
        .header-menu-section{
            .header-right {
                .login-button {
                    padding: 12px 10px;
                }
                .language-dropdown {
                    .p-dropdown-label{
                        font-size: 16px;
                    }
                    .p-dropdown-trigger{
                        width: 2.5rem;
                    }
                }
            }
            .p-menubar .p-menubar-start{
                margin-right: 0px;
            }
        }
    }
    .p-toast{
        max-width: 90%;
    }
}
@media (max-width:768px){
    .show-on-mobile{
        display: block;
    }
    .hide-on-mobile{
        display: none;
    }
}
.full-window-loading {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    background: rgba(14, 0, 32, 0.85);
    z-index: 9999;
    p{
        margin: 0px;
        color: $white;
    }
    &.payment-loading{
        position: relative;
        width: 100%;
        height: calc(100% - 105px);
        min-height: 500px;
        .pi-spinner{
            font-size: 1.3rem;
            margin-right: 0.8rem;
        }
    }
}