@import '../../assets/scss/variables';
.p-dropdown-item.p-focus{
    border-radius: 8px !important;
    border: 1px solid #504361 !important;
    background: #3E2E54 !important;
}
.bundle-detail{
    &-blk{
        max-width: 600px;
        position: relative;
        z-index: 2;
        border-radius: 28px;
        overflow: hidden;
        width: 95%;
        font-size: 20px;
        line-height: 32px;
        .p-dialog-header{
            padding-top: 50px;
        }
        &.hide-close .p-dialog-header-icons{
            display: none !important;
        }
        &.p-dialog .p-dialog-header,.p-dialog-content{
            background-color: $secondary-bg;
            color: $white;
            padding-left: 50px;
            padding-right: 50px;
            &::-webkit-scrollbar {
                width: 4px;
                border-radius: 5px;
            }
            &::-webkit-scrollbar-track {
                background: $purple-dark;
                border-radius: 5px;
            }
            &::-webkit-scrollbar-thumb {
                background: #625376;
                border-radius: 5px;
                padding: 2px;
            }
        }
        .p-dialog-content{
            padding-bottom: 50px;
        }
        .p-dialog-header-icon{
            padding: 6px !important;
            width: 32px;
        }
        .p-dialog-header-icons{
            border-radius: 25px;
            &:hover{
                background: $white;
                .p-link{
                    svg.p-icon{
                        color: $accent;
                    }
                }
                
            }
            .p-link{
                svg.p-icon{
                    width: 30px;
                    height: 30px;
                    color: $white;
                }
               &:hover{
                    background: none;
               }
            }
        }
        h4{
            font-size: 27px;
            line-height: 40px;
            font-weight: 500;
            margin: 0;
        }
        p{
            margin: 0;
        }
        .price{
           color: $primary;
           font-size: 30px;
           line-height: 43px;
           font-weight: 600;
        }
        .desc{
            font-size: 18px;
            line-height: 27px;
            font-weight: 500;
        }
        .details-list {
            padding: 0;
            margin: 0;
            li{
                list-style: none;
                display: flex;
                justify-content: space-between;
                padding-bottom: 18px;
            }
        }
        button{
            width: 100%;
            padding: 12px 25px;
            .p-button-label{
                font-size: 22px;
                font-weight: 600;
                line-height: 150%;
            }
            &.button-secondary{
                &:hover{
                    border-color: $primary;
                }
            }
            &.button-primary{
                &:hover{
                    background: $white;
                    border-color: $white;
                    .p-button-label{
                        color: $accent;
                    }
                }
            }
        }
        .head-blk{
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid $border-color;
            padding-bottom: 24px;
            margin-bottom: 24px;
        }
        .column-sep{
            column-count: 3;
        }
    }
}

.flag-icon img{
    width: 70px;
    height: 70px;
    border-radius: 70px;
    object-fit: cover;
}
.bundles-section{
    width: 90%;
    max-width: 1265px;
    margin: 0 auto;
    padding: 0rem 0rem 5rem;
    .filter-tab{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 20px;
        
        .active-p{
            color:$black;
            background: $white;
            width: 76.08px;
            height: 27.87px;
            font-size: 10.87px;
            border-width: 1px;
            border-style: dotted;
            text-align: center;
            border-radius: 6px;
            margin: 10px 7px;
            align-content: center;
            cursor: pointer;
        }
        .inactive-p{
            color:$white;
            width: 76.08px;
            height: 27.87px;
            font-size: 10.87px;
            border-width: 1.4px;
            border-style: dotted;
            text-align: center;
            border-radius: 6px;
            margin: 10px 7px;
            align-content: center;
            cursor: pointer;
        }
    }
    .dropdown-block{
        width: 90%;
        max-width: 625px;
        position: relative;
        .country-dropdown{
            padding-left: 3.5rem;
        }
        .pi-search{
            position: absolute;
            color: $white;
            left: 12px;
            top: 18px;
            font-size: 26px;
        }
    }
    .country-dropdown{
        width: 100%;
        background: transparent;
        border: none;
        border-radius: 0px;
        border-bottom: 2.862px solid rgba(255, 255, 255, 0.69);
        box-shadow: 0px 1.431px 2.862px 0px rgba(16, 24, 40, 0.05);
        &.currency-dropdown{
            max-width: 155px;
        }
        .p-dropdown-label{
            font-size: 25px;
            font-weight: 500;
            line-height: 34px;
            color: $white;
        }
        .p-dropdown-trigger{
            color: $white;
        }
    }
    .bundles-list-section{
        margin-top: 5rem;
        position: relative;
        .bundle-box{
            border-radius: 32px;
            background: #160033;
            padding: 2.5rem;
            color: $white;
            background-image: url('../../assets/images/bundle-bg.png');
            background-repeat: no-repeat;
            background-size: cover;
            position: relative;
            overflow: hidden;
            max-width: 385px;
            margin: 0 auto 3rem;
            transition: transform .2s;
            .flag-icon{
                line-height: 0px;
                font-size: 50px;
            }
            .icon{
                line-height: 0px;
            }
            h2{
                margin: 0px;
                font-size: 30px;
                font-weight: 600;
                line-height: 24px;
            }
            h3{
                font-size: 55px;
                font-weight: 600;
                line-height: 100%;
                letter-spacing: -2.732px;
                margin: 1.5rem 0rem 2rem;
            }
            a{
                font-size: 16px;
                font-weight: 400;
                letter-spacing: -0.8px;
                color: $white;
                border-bottom: 1px solid $white;
                padding-bottom: 2px;
                transition: 0.4s;
                &:hover{
                    color: $primary;
                    border-bottom: 1px solid $primary;
                }
            }
            .buy-button{
                background: $accent;
                border-radius: 12px;
                padding: 10px 25px;
                border: none;
                background-image: url('../../assets/images/bundle-bg.png');
                transition: transform .2s;
                .p-button-label{
                    font-size: 20px;
                    font-weight: 600;
                    position: relative;
                    z-index: 0;
                    line-height: 38px;
                    letter-spacing: -1.195px;
                }
                &:hover{
                    background: $primary;
                    transform: scale(1.1);
                }
            }
            &::before{
                content: '';
                width: 100px;
                height: 100px;
                background: $accent;
                position: absolute;
                border-radius: 100%;
                left: 0rem;
                bottom: 0rem;
                z-index:0;
                filter: blur(50px);
                -webkit-filter: blur(50px);
                opacity: 0.75;
            }
            &::after{
                content: '';
                width: 200px;
                height: 200px;
                background: $accent;
                position: absolute;
                border-radius: 100%;
                right: 1rem;
                top: -6rem;
                z-index:0;
                filter: blur(50px);
                -webkit-filter: blur(50px);
                opacity: 0.5;
            }
            .popular-tag{
                border-radius: 0px 0px 15px 15px;
                background: #F2C215;
                color: $purple-dark;
                position: absolute;
                top: 0px;
                width: 100%;
                max-width: 125px;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 5px;
                height: 28px;
                margin: 0 auto;
                left: 0px;
                right: 0px;
                z-index: 9;
                span{
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 17px;
                }
                .pi{
                    font-size: 14px;
                }
            }
            &:hover{
                transform: scale(1.1);
            }
        }
        .p-carousel-indicators{
            display: none;
        }
        .p-carousel-container{
            .p-link{
                background: #312343;
                border: 1px solid #312343;
                left: 0;
                margin: 0 auto;
                position: absolute;
                right: 0;
                bottom: -30px;
                top: auto;
                height: 40px;
                width: 40px;
                .p-icon{
                    color: $white;
                }
                &.p-carousel-next{
                    right: -60px;
                    z-index: 1;
                }
                &.p-carousel-prev{
                    left: -60px;
                    z-index: 1;
                }
                &.p-disabled{
                    background:transparent;
                    opacity: .65;
                    border: 1px solid #312343;
                    .p-icon{
                        color: $white;
                        opacity: .50;
                    }
                }
            }
        }
    }
}

.list-container{
    .view-more-button{
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        background: transparent;
        border-radius: 12px;
        padding: 15px 35px;
        .p-button-label{
            font-size: 20px;
            font-weight: 600;
            letter-spacing: -1px;
        }
        .p-button-icon{
            color: $primary;
        }
        .onemore-arrow-icon{
            position: relative;
            right: -8px;
            color: $primary;
        }
        &:hover{
            .p-button-label{
                color: $primary;
            }
            .p-button-icon{
                color: $white;
            }
            .onemore-arrow-icon{
                color: $white;
            }
        }
        &:focus{
            box-shadow: none;
        }
    }
}

.coming-soon-section{
    padding: 2.5rem 0rem;
    text-align: center;
    color: $white;
    width: 95%;
    max-width: 623px;
    margin: 1rem auto 0rem;
    border: 1px dashed #382d48;
    border-radius: 10px;
    p{
        margin: 1.5rem;
        font-size: 18px;
    }
    .logo-img{
        img{
            max-width: 200px;
        }
    }
}

// Responsive CSS

@media (max-width:768px){
    .bundles-section .bundles-list-section .bundle-box{
        max-width: 100%;
        padding: 1.5rem;
        &:hover{
            transform: scale(1.01);
        }
    }
    .bundle-detail{
        &-blk{
            &.p-dialog .p-dialog-header,.p-dialog-content{
                padding-left: 25px;
                padding-right: 25px;
            }
            .p-dialog-header{
                padding-top: 25px;
            }
            .p-dialog-content{
                padding-bottom: 25px;
            }
            button{
                padding: 10px 25px;
                .p-button-label{
                    font-size: 18px;
                }
            }
        }
    }
}
@media (min-width:1200px) and (max-width:1400px){
    .bundles-section{
        .bundles-list-section{
            .bundle-box{
                h3{
                    font-size: 50px;
                }
            }
        }
    }
}
@media (max-width:991px){
    .bundles-section{
        padding: 0rem 0rem 5rem;
        .bundles-list-section{
            margin-top: 2.5rem;
            .bundle-box{
                margin: 0 auto 2rem;
                h2{
                    font-size: 28px;
                }
                h3{
                    font-size: 42px;
                }
               .buy-button .p-button-label{
                    font-size: 18px;
               }
            }
        }
        .country-dropdown .p-dropdown-label {
            font-size: 16px;
        }
    }
}
@media (min-width:992px) and (max-width:1199px){
    .bundles-section{
        .bundles-list-section{
            .bundle-box{
                padding: 1.5rem;
                h3{
                    font-size: 45px;
                }
                h2{
                    font-size: 24px;
                }
                .buy-button{
                    padding: 10px 12px;
                    .p-button-label{
                        font-size: 16px;
                    }
                }
                .gap-5{
                    gap: 1rem !important;
                }
            }
        }
    }
}
@media (min-width:1200px) and (max-width:1340px){
    .bundles-section .bundles-list-section{
        .bundle-box{
            .buy-button{
                padding: 10px 20px;
                .p-button-label{
                    font-size: 18px;
                }
            }
        }
    }
}