@import '../../assets/scss/variables';

.loader{
    position: fixed;
    span{
        top: 0px;
    }
}

.redeemvoucher-section{
    width: 90%;
    max-width: 900px;
    margin: 0 auto;
    color: $white;
    padding-bottom: 10rem;
    h1{
        margin: 0px;
        font-size: 50px;
        font-weight: 500;
        line-height: 160%;
    }
    p{
        font-size: 22px;
        font-weight: 400;
        line-height: 160%;
        color: #CED2DD;
        margin: 0px;
    }
    .error-message{
        color: $primary;
        font-size: 13px;
        margin: 5px 2px;
    }
    .redeemvoucher-wrapper{
        text-align: left;
        border-radius: 9px;
        background: $purple-dark;
        padding: 36px;
        margin-top: 3.5rem;
        .form-block{
            .input-field{
                margin-bottom: 0.875rem;
                label{
                    font-size: 20px;
                    font-weight: 400;
                    line-height: 160%;
                }
                .p-inputtext{
                    border-radius: 8px;
                    border: 1px solid rgba(226, 227, 238, 0.13);
                    background: rgba(22, 0, 51, 0.10);
                    padding: 14px 20px;
                    color: $white;
                    font-family: $font-base;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 160%;
                    width: 100%;
                    .p-input-icon{
                        svg{
                            &:focus{
                                outline: none;
                                box-shadow: none;
                            }
                        }
                    }
                    &::placeholder{
                        color: #b7b7b7;
                    }
                }
                .p-icon-field{
                    width: 100%;
                }
            }
            .redeemvoucher-button{
                background: $accent;
                border-radius: 12px;
                transition: 0.8s;
                padding: 14px 40px;
                border: none;
                margin-top: 0.5rem;
                .p-button-label{
                    font-size: 21px;
                    font-weight: 600;
                    position: relative;
                    z-index: 0;
                    line-height: 35.567px;
                }
                &:hover{
                    background: $primary;
                }
            }
            .link-p{
                font-size: 22px;
                font-weight: 400;
                line-height: 160%;
                color: #DADDE5;
                a{
                    font-weight: 500;
                    color: $white;
                    margin-left: 5px;
                    &:hover{
                        color: $primary;
                    }
                }
            }
        }
        h4{
            font-size: 27px;
            line-height: 40px;
            font-weight: 500;
            margin: 0;
        }
        .details-header{
            border-bottom: 1px solid rgba(226, 227, 238, 0.13);
            padding-bottom: 1.2rem;
        }
        .details-list{
            margin: 2rem 0px 0px;
            padding: 0px;
            li{
                list-style: none;
                display: flex;
                justify-content: space-between;
                padding-bottom: 20px;
                font-size: 20px;
                line-height: 32px;
            }
        }
        .vouchers-box{
            border-radius: 15px;
            .box{
                padding: 12px;
                .icon{
                    width: 50px;
                    height: 50px;
                    border-radius: 8px;
                    .pi{
                        font-size: 20px;
                    }
                }
                .text h2{
                    font-size: 18px;
                    line-height: 24px;
                }
            }
            .form-wrapper .details-btn{
                margin-top: 1.3rem;
            }
            .topup-esim{
                
                h2{
                    font-size: 26px;
                    font-weight: 500;
                    margin: 0px 0px 32px;
                }
                .esim-list{
                    padding: 20px 25px;
                    margin-bottom: 30px;
                    border-color: white;
                    border-radius: 0.5rem;
                    border: 1.48px solid #E2E3EE21;
                    color: $white;
                    font-size: 18px;
                    font-weight: 500;
                    box-shadow: none;
                    &:focus{
                        box-shadow: none;
                    }
                    &::placeholder{
                        color: $white;
                    }
                    .left{
                        text-align: left;
                        p{
                            margin: 0px;
                            font-size: 18px;
                            font-weight: 500;
                            color: $white;
                            margin-bottom: 10px;
                        }
                        span{
                            color: $white;
                            font-size: 16px;
                            font-weight: 500;
                        }
                    }
                    .right{
                        .topup-btn{
                            border: none;
                            border-radius: 30px;
                            background: $accent;
                            box-shadow: 0px 8px 20px 0px rgba(89, 51, 138, 0.30);
                            padding: 13px 22px;
                            width: 170px;
                            .p-button-label{
                                font-size: 15px;
                                font-weight: 500;
                            }
                            &:hover{
                                background: $primary;
                            }
                        }
                    }
                    &:last-child{
                        margin-bottom: 0px;
                    }
                }
               }
        }
    }
    .no-spinner::-webkit-outer-spin-button,
    .no-spinner::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Hide spinners in Firefox */
    .no-spinner[type='number'] {
        -moz-appearance: textfield;
    }
}


// Responsive CSS

@media (max-width:768px){
    .redeemvoucher-section{
        h1{
            font-size: 34px;
        }
        p{
            font-size: 18px;
        }
        .redeemvoucher-wrapper{
            padding: 30px 20px;
            .form-block{
                .input-field{
                    .p-inputtext{
                        font-size: 16px;
                    }
                    label{
                        font-size: 18px; 
                    }
                }
                .forgot-pass-p, .link-p{
                    font-size: 18px;
                }
                .redeemvoucher-button{
                    padding: 10px 40px;
                    .p-button-label{
                        font-size: 18px;
                    }
                }
            }
            .vouchers-box{
                border-radius: 15px;
                .box{
                    padding: 12px;
                    .icon{
                        width: 50px;
                        height: 50px;
                        border-radius: 8px;
                        .pi{
                            font-size: 20px;
                        }
                    }
                    .text h2{
                        font-size: 18px;
                        line-height: 24px;
                    }
                }
                .form-wrapper .details-btn{
                    margin-top: 1.3rem;
                }
                .topup-esim{
                    
                    h2{
                        font-size: 26px;
                        font-weight: 500;
                        margin: 0px 0px 32px;
                    }
                    .esim-list{
                        padding: 20px 25px;
                        margin-bottom: 30px;
                        border-color: white;
                        border-radius: 0.5rem;
                        border: 1.48px solid #E2E3EE21;
                        color: $white;
                        font-size: 18px;
                        font-weight: 500;
                        box-shadow: none;
                        &:focus{
                            box-shadow: none;
                        }
                        &::placeholder{
                            color: $white;
                        }
                        .left{
                            text-align: left;
                            p{
                                margin: 0px;
                                font-size: 18px;
                                font-weight: 500;
                                color: $white;
                                margin-bottom: 10px;
                            }
                            span{
                                color: $white;
                                font-size: 16px;
                                font-weight: 500;
                            }
                        }
                        .right{
                            .topup-btn{
                                border: none;
                                border-radius: 30px;
                                background: $accent;
                                box-shadow: 0px 8px 20px 0px rgba(89, 51, 138, 0.30);
                                padding: 13px 22px;
                                width: 170px;
                                .p-button-label{
                                    font-size: 15px;
                                    font-weight: 500;
                                }
                                &:hover{
                                    background: $primary;
                                }
                            }
                        }
                        &:last-child{
                            margin-bottom: 0px;
                        }
                    }
                   }
            }
        }
    }
}