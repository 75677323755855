@import '../../assets/scss/variables';
.btn-disable {
    opacity: 0.5;
    pointer-events: none;
}
.payment-section{
    width: 90%;
    max-width: 1130px;
    margin: 0 auto;
    color: $white;
    padding: 1rem 0rem 5rem;
    .p-inputtext{
        border-radius: 9px;
        border: 1.482px solid rgba(226, 227, 238, 0.13);
        background: rgba(22, 0, 51, 0.10);
        width: 100%;
        padding: 15px 25px;
        color: $white;
        font-size: 18px;
        font-weight: 400;
        line-height: 160%;
        &::placeholder{
            color: #565555;
        }
    }
    .p-dropdown{
        border-radius: 9px;
        border: 1.482px solid rgba(226, 227, 238, 0.13);
        background: rgba(22, 0, 51, 0.10);
        width: 100%;
        color: #F1F1F1;
        font-size: 17.783px;
        font-weight: 400;
        line-height: 160%;
        .p-inputtext{
            border: 0;
        }
    }
    .summary-block{
        padding: 25px;
        border-radius: 12px;
        background: $purple-dark;
        h2{
            margin: 0px;
            padding: 0rem 0rem 1.4375rem;
            font-size: 22px;
            font-weight: 500;
            line-height: 160%;
        }
        ul{
            margin: 0px;
            padding: 0px;
            li{
                list-style: none;
                margin: 0.9375rem 0rem;
                color: #C9C9C9;
                font-size: 19px;
                font-weight: 400;
                line-height: 160%;
                padding-left: 2.2rem;
                position: relative;
                display: flex;
                &:last-child{
                    margin: 0rem 0rem;
                }
                &::before{
                    content: '\e909';
                    position: absolute;
                    font-family: primeicons;
                    left: 0px;
                    font-size: 18px;
                }
                .pi{
                    position: relative;
                    // top: 1px;
                    // left: 5px;
                    cursor: pointer;
                    &:hover{
                        color: $primary;
                    }
                }
            }
        }
        .coupon-field{
            position: relative;
            margin-bottom: 1.8525rem;
            .p-inputtext{
                border-radius: 9px;
                border: 1.482px solid rgba(226, 227, 238, 0.13);
                background: rgba(22, 0, 51, 0.10);
                width: 100%;
                padding: 15px 25px;
                color: #C9C9C9;
                font-size: 17.783px;
                font-weight: 400;
                line-height: 160%;
            }
            .apply-button{
                position: absolute;
                right: 1px;
                top: 3px;
                background: $purple-dark;
                border: none;
                .p-button-label{
                    color: $white;
                    font-size: 19.78px;
                    font-weight: 500;
                    line-height: 160%;
                }
                &:hover{
                    .p-button-label{
                        color: $primary;
                    }
                }
            }
        }
        .order{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 0.9375rem 0rem;
            span{
                color: #C9C9C9;
                font-size: 19px;
                font-weight: 400;
                line-height: 160%;
            }
            &:last-child{
                margin: 0rem 0rem;
            }
        }
    }
    .payment-methods{
        padding: 36px;
        background: $purple-dark;
        border-radius: 9px;
        .StripeElement{
            width: 100%;
            min-height: 60px;
            &.StripeElement--empty{
                padding: 20px 25px;
            }
            *{
                font-size: 18px;
            }
        }
        .p-tabview{
            .p-tabview-nav-container{
                .p-tabview-nav-content{
                    .p-tabview-nav{
                        display: inline-flex;
                        padding: 9px;
                        border-radius: 9px;
                        border: 1.482px solid rgba(226, 227, 238, 0.14);
                        background: transparent;
                        width: 100%;
                        align-items: center;
                        .p-unselectable-text{
                            width: 100%;
                            cursor: pointer;
                            .tab{
                                padding: 16px 20px;
                                font-size: 20px;
                                font-weight: 500;
                                color: $white;
                                border: none;
                                border-radius: 4px;
                                background: $purple-dark;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                .p-image{
                                    line-height: 0px;
                                    filter: brightness(100%) contrast(100%);
                                }
                            }
                            &.p-tabview-selected{
                                .tab{
                                    background: $white;
                                    color: $purple-dark;
                                    .p-image{
                                        filter: brightness(0%) contrast(100%);
                                    }
                                }
                            }
                        }
                        .p-tabview-ink-bar{
                            display: none;
                        }
                    }
                }
            }
            .p-tabview-panels{
                padding: 0px;
                background: transparent;
                .card-section{
                    margin-top: 2.4375rem;
                }
            }
        }
    }
}
.payment-methods{
    h3{
        color: $white;
        font-size: 22px;
        font-weight: 500;
        line-height: 160%;
    }
    .pi-credit-card{
        position: absolute;
        color: #F1F1F1;
        font-size: 26px;
        right: 22px;
        top: 18px;
    }
    .pay-button{
        padding: 14.82px 41.495px;
        width: 100%;
        background: $accent;
        border: none;
        border-radius: 12px;
        .p-button-label{
            font-size: 23.711px;
            font-weight: 600;
            line-height: 35.567px;
        }
        &:hover{
            background: $primary;
        }
        &.paypal-button{
            background: #FFC439;
            justify-content: center;
            padding: 22px 40px;
            .p-image{
                line-height: 0px;
            }
            &:hover{
                background: $white;
            }
        }
    }
    label{
        color: $white;
        font-size: 20.747px;
        font-weight: 400;
        line-height: 160%;
        a{
            color: $primary;
            font-weight: 600;
            &:hover{
                text-decoration: underline;
            }
        }
    }
    p{
        color: #EFEFEF;
        font-size: 18.78px;
        font-weight: 400;
        line-height: 26.675px;
        margin: 0px;
        padding: 1.6875rem 0rem 0rem;
    }
    .p-checkbox{
        position: relative;
        top: 3px;
        .p-checkbox-box{
            background: $purple-dark;
            border-radius: 2px;
            width: 25px;
            height: 25px;
            min-width: 25px;
            &:hover{
                border: 2px solid $accent;
            }
            .p-checkbox-icon.p-icon{
                width: 15px;
                height: 15px;
            }
        }
        &.p-highlight{
            .p-checkbox-box{
                border: 2px solid $accent; 
            }
        }
    }
}
.roaming-countries-dialog{
    width: 100%;
    max-width: 950px;
    padding: 2.4306rem 0.5rem 2.4306rem 2.4306rem;
    border-radius: 20px;
    background: $purple-dark;
    .p-dialog-header{
        padding: 0rem 0rem 1.5rem;
        margin: 0rem 2.4306rem 2rem 0rem;
        background: none;
        border-bottom: 1px solid #404040;
        .p-dialog-header-icons{
            padding: 6px;
            border-radius: 25px;
            &:hover{
                background: $white;
                .p-link{
                    svg.p-icon{
                        color: $accent;
                    }
                }
                
            }
            .p-link{
                svg.p-icon{
                    width: 30px;
                    height: 30px;
                    color: $white;
                }
               &:hover{
                    background: none;
               }
            }
        }
        .p-dialog-title{
            color: $white;
            font-size: 27.33px;
            font-weight: 500;
            line-height: 150%;
        }
    }
    .p-dialog-content{
        padding: 0rem;
        background: transparent;
        color: $white;
        .content{
            ul{
                margin: 0px;
                padding: 0px 20px;
                li{
                    font-size: 20px;
                    font-weight: 400;
                    line-height: 200%;
                }
            }
        }
        &::-webkit-scrollbar {
            width: 4px;
            border-radius: 5px;
        }
        &::-webkit-scrollbar-track {
            background: $purple-dark;
            border-radius: 5px;
        }
        &::-webkit-scrollbar-thumb {
            background: #625376;
            border-radius: 5px;
            padding: 2px;
        }
    }
    &.networks-dialog{
        max-width: 680px;
        .p-dialog-content{
            .content{
                grid-template-columns: 100%;
            }
        }
    }
}

.applied-coupon{
    position: relative;
    display: flex;
    align-items: center;
    border: 1px solid $accent;
    padding: 10px 15px;
    border-radius: 9px;
    &-close{
        background-color: $btn-bg;
        padding: 5px;
        border-radius: 100%;
        cursor: pointer;
        margin-left: 10px;
    }
}
.loader{
    font-size: 25px;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9;
    background-color: rgba(14, 0, 32, 0.7);
    span{
        position: relative;
        // top: -20rem;
    }
    i{
        margin: 5px;
        font-size: 20px;
    }
}

.payment-iframe {
    border: 1px solid #ddd;
    border-radius: 10px;
    height: 650px;
    margin-top: 30px;
}

@media (max-width: 768px) {
    .payment-iframe {
        height: 450px;
        margin-top: 20px;
    }
}

@media (min-width: 1024px) and (max-width: 1360px) {
    .payment-iframe {
        height: 600px;
        margin-top: 20px;
    }
}

.payment-sheet-changing-loading {
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    .pi-spin {
        color: white;
        font-size: 24px;
    }
}