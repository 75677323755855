@import '../../assets/scss/variables';

.payment-success-section{
    width: 90%;
    max-width: 1115px;
    margin: 0 auto;
    color: $white;
    padding: 1rem 0rem 5rem;
    .qrcode-block{
        padding: 35px;
        border-radius: 12px;
        background: $purple-dark;
        .qrcode-wrapper{
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            .qrcode{
                img{
                    width: 100%;
                    max-width: 423px;
                    filter: blur(3.672233819961548px);
                }
            }
            &.show-qr .qrcode img{
                filter: unset;
            }
            .eye-icon{
                background: $white;
                display: inline-block;
                padding: 10px;
                border-radius: 7px;
                position: absolute;
                .pi{
                    color: $black;
                    font-size: 40px;
                }
                &:hover{
                    background: $primary;
                    cursor: pointer;
                    .pi{
                        color: $white;
                    }
                }
            }
        }
        h2{
            margin: 1.2rem 0rem 0rem;
            font-size: 29px;
            font-weight: 500;
            line-height: 145%;
        }
        p{
            margin: 1rem 0rem 0rem;
            color: #BEC4D2;
            font-size: 24px;
            font-weight: 400;
            line-height: 150%;
        }
    }
    .payment-details-block{
        padding: 35px 30px;
        border-radius: 12px;
        background: $purple-dark;
        margin-left: 10px;
        ul{
            margin: 0px;
            padding: 1.3rem 0rem;
            border-bottom: 1px solid #2F1E45;
            &:first-child{
                padding: 0rem 0rem 1.0938rem;
            }
            &:last-child{
               border-bottom: 0px;
               padding: 1.3rem 0rem 0rem;
            }
            li{
                list-style: none;
                color: #C7CAD0;
                font-size: 19.964px;
                font-weight: 400;
                line-height: 160%;
                &:last-child{
                    color: $white;
                    font-weight: 500;
                    text-align: right;
                }
                .completed{
                    border-radius: 9px;
                    background: #E1F4E2;
                    padding: 10px 15px;
                    color: #0C8F09;
                    font-size: 17px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 160%;
                }
                .success{
                    line-height: 0px;
                }
                h2{
                    margin: 0px;
                    color: $white;
                    font-size: 30px;
                    font-weight: 500;
                    line-height: 150%;
                }
                p{
                    margin: 0px;
                    color: #C7CAD0;
                    font-size: 24px;
                    font-weight: 400;
                    line-height: 160%;
                }
                .pi{
                    font-size: 30px;
                }
                h3{
                    margin: 0px;
                    color: $white;
                    font-size: 27px;
                    font-weight: 500;
                    line-height: 160%;
                }
                .view-button {
                    background: $accent;
                    border: none;
                    padding: 12px 20px;
                    width: 100%;
                    text-align: center;
                    height: 64px;
                    border-radius: 12px;
                    .p-button-label {
                        color: $white;
                        font-size: 22px;
                        font-weight: 600;
                        line-height: 160%;
                    }
                
                    &:hover {
                        background: $primary; 
                    }
                }
                
                /* Underline text for "Buy another eSIM?" */
                .underline-button {
                    background-color: transparent;
                    text-decoration: underline;
                    border: none;
                    color: white;
                    cursor: pointer;
                    text-align: center;
                    width: 100%; 
                    display: inline-block;
                    padding: 8px 0;
                    font-size: 16px;
                    margin-top: 12px;
                    span {
                        font-weight: normal;
                    }
                    .p-button .p-button-label {
                        color: white;
                    }
                    &:hover{
                        color: $primary;
                    }
                }
                .mt-2 {
                    margin-top: 4rem;
                }
                
            }
            &.header-ul{
                padding-bottom: 2rem;
            }
        }
    }
    .payment-details{
        display: flex;
        justify-content: center;
        margin-top: 30px;
        .download-button{
            background: $accent;
            border: none;
            padding: 12px 20px;
            .p-button-label{
                color: $white;
                font-size:22px;
                font-weight: 600;
                line-height: 160%;
            }
            &:hover{
                background: $primary;
            }
        }
    }
}
.qrcode-dialog{
    width: 95%;
    max-width: 500px;
    .p-dialog-header{
        margin: 0rem 2rem 2rem 0rem;
        .p-dialog-header-icon{
            &:focus{
                box-shadow: none;
            }
        }
    }
    .qr-content{
        .qrcode{
            img{
                width: 100%;
                max-width: 423px;
                border-radius: 5px;
            }
        } 
    }
}

@media (max-width:768px){
    .payment-success-section .payment-details-block{
        ul{
            display: block !important;
            li:last-child{
                margin-top: 1rem;
                text-align: left;
            }
        }
    }
}
@media (min-width:769px) and (max-width:991px){
    .payment-success-section{
        .payment-details-block{
            ul{
                display: block !important;
                li{
                    &:last-child{
                        margin-top: 1rem;
                        text-align: left;
                    }
                    h2{
                        font-size: 24px;
                    }
                }
            }
        }
        .qrcode-block{
            h2{
                font-size: 24px;
            }
            p{
                font-size: 18px;
            }
        }
    }
}

.order-in-progress {
    color: white !important;
}