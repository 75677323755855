@import '../../assets/scss/variables';

.compatible-devices-section{
    width: 90%;
    max-width: 1400px;
    margin: 0 auto;
    color: $white;
    padding: 2rem 0rem 10rem;
    h1{
        margin: 0px;
        font-size: 52px;
        font-weight: 500;
        line-height: 160%;
        margin: 0px;
    }
    p{
        font-size: 26px;
        font-weight: 400;
        line-height: 160%;
        margin: 0px;
    }
    .devices-section{
        margin-top: 7.25rem;
        p{
            font-size: 20px;
            font-weight: 400;
            line-height: 120%;
            text-align: center;
            span{
                color: $primary;
            }
        }
        .p-tabview{
            margin-top: 4.35rem;
            .p-tabview-nav-container{
                .p-tabview-nav-content{
                    background: #21182D;
                    border-radius: 50px;
                    .p-tabview-nav{
                        display: inline-flex;
                        border-radius: 50px;
                        width: 100%;
                        background: #21182D;
                        border: none;
                        margin: 0 auto;
                        justify-content: space-between;
                        .p-unselectable-text{
                            position: relative;
                            .p-tabview-nav-link{
                                padding: 1.5rem 3rem;
                                background: #21182D;
                                color: $white;
                                border-radius: 50px;
                                border: none;
                                .p-tabview-title{
                                    font-weight: 400;
                                    font-size:18px;
                                    font-family: $font-base;
                                }
                                &:focus{
                                    box-shadow: none;
                                }
                            }
                            &.p-highlight{
                                &:after{
                                    display: none;
                                }
                                .p-tabview-nav-link{
                                    background: $accent;
                                    color: $white;
                                    &:after{
                                        display: none;
                                    }
                                }
                            }
                            &.last-tab{
                                &:after{
                                    display: none;
                                }
                            }
                            &:after{
                                background: rgba(159, 169, 192, 0.25);
                                content: "";
                                height: 25px;
                                position: absolute;
                                right: 0;
                                width: 2px;
                                top: 20px;
                           }
                        }
                        .p-tabview-ink-bar{
                            display: none;
                        }
                    }
                }
            }
            .p-tabview-panels{
                background: transparent;
                padding: 2rem 0px;
                h2{
                    margin-bottom: 2rem;
                    font-size: 28px;
                    font-weight: 400;
                    padding: 0px 3rem;
                    color: $white;
                }
                .p-accordion{
                    padding: 0px;
                    margin: 0px;
                }
                .custom-margin{
                    padding-top: 4.5rem;
                }
            }
            .device-panel{
                color: $white;
                display: grid;
                grid-template-columns: auto auto auto;
                margin-top: 4.5rem;
                ul{
                    margin: 0px;
                    padding: 0px 3rem;
                    li{
                        list-style: none;
                        font-size: 18px;
                        font-weight: 400;
                        line-height: 280%;
                    }
                }
            }
        }
    }
}