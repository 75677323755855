@import '../../assets/scss/variables';
.faq{
    &-blk{
        position: relative;
        min-height: 60vh;
        .search-input-icon{
            position: absolute;
            right: 7px;
            top: 7px;
        }
        .p-accordion-header-text{
            font-size: 28px;
            font-weight: 600;
            padding-right: 3rem;
            line-height: 140%;
            letter-spacing: -1.404px;
        }
        .p-accordion{
            .p-accordion-tab{
                margin: 1rem 0rem;
                .p-accordion-header .p-accordion-header-link{
                    background-color: $main-bg;
                    border: 0;
                    color: $white;
                    overflow: hidden;
                    border-radius: 23px;
                    padding: 35px 35px 20px;
                    .p-accordion-toggle-icon{
                        display: none;
                    }
                    &::after{
                        content: "\e90d";
                        font-family: primeicons;
                        font-size: 20px;
                    }
                }
                &.p-accordion-tab-active{
                    .p-accordion-header .p-accordion-header-link{
                        &::after{
                            content: "\e90b";
                        }
                    }
                }
            }
            .p-accordion-content{
                font-size: 22px;
                line-height: 33px;
                border-radius: 0px 0px 23px 23px;
                border: 0;
                background: #241B30;
                color: $white;
                padding: 0px 35px 35px;
                p:first-child{
                    margin-top: 0;
                }
            }
        }
        .p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link{
            background: #241B30;
            color: $white;
            border-radius: 23px 23px 0px 0px;
        }
    }
    &-section{
        max-width: 1100px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        padding-left: 12px;
        padding-right: 12px;
        color: $white;
        .tablist{
            ul[role="tablist"]{
                display: none;
            }
        }
        h1{
            color: $white;
            font-size: 52px;
            font-weight: 500;
            line-height: 160%;
            margin: 5px 0px;
        }
        p{
            color: #CED2DD;
            font-size: 23px;
            font-weight: 400;
            line-height: 150%;
            letter-spacing: -1.123px;
        }
        ol, ul{
            li{
                margin: 10px 0px;
                line-height: 150%;
                letter-spacing: -1.123px;
                font-size: 22px;
            }
        }
    }
}
.search-input-icon{
    position: relative;
    width: 100%;
    max-width: 625px;
    margin: 0 auto 2rem;
    .pi-search{
        position: absolute;
        left: 10px;
        top: 18px;
        font-size: 24px;
    }
    .pi-chevron-right{
        position: absolute;
        right: 5px;
        top: 24px;
    }
    input{
        background-color: $main-bg;
        color: $white;
        outline: none;
        border: none;
        border-radius: 0px;
        border-bottom: 2.862px solid rgba(255, 255, 255, 0.69);
        box-shadow: 0px 1.431px 2.862px 0px rgba(16, 24, 40, 0.05);
        width: 100%;
        padding-left: 3rem;
        font-size: 25px;
        font-weight: 500;
        line-height: 34.348px;
        &::placeholder{
            color: #6A6A6A;
        }
    }
}

@media (max-width:768px){
    .faq-section{
        padding-top: 1rem;
        h1{
            font-size: 42px;
            line-height: 105%;
            letter-spacing: -2.1px;
        }
        p{
            font-size: 18px;
            line-height: 140%;
        }
        ol, ul{
            li{
                font-size: 18px;
            }
        }
        h4{
            font-size: 20px;
        }
    }
    .faq-blk{
        .p-accordion-header-link{
            padding: 20px 20px 20px !important;
        }
        .p-accordion-header-text{
            font-size: 22px;
        }
        .p-accordion-content{
            padding: 0px 20px 20px !important;
        }
    }
}