@import '../../assets/scss/variables';

.header-banner-section{
    width: 90%;
    max-width: 1420px;
    margin: 0 auto;
    padding: 5rem 0rem;
    color: $white;
    .affiliate-h1{
        margin: 0px;
        font-size: 96px;
        font-weight: 600;
        line-height: 100%;
        letter-spacing: -4.8px;
    }
    p{
        font-size: 36px;
        font-weight: 400;
        letter-spacing: -1.8px;
        padding: 0rem 2rem;
    }
    .open-button{
        border-radius: 30px;
        background: $accent;
        height: 100px;
        padding: 45px 86px;
        border: none;
        margin-top: 80px;
        .p-button-label{
            font-size: 40px;
            font-weight: 700;
            line-height: 41.588px;
            letter-spacing: -2px;
            position: relative;
            z-index: 2;
        }
        &:hover{
            background: $primary;
        }
    }
}

.how-it-works-section{
    padding: 15rem 0rem;
    position: relative;
    color: $white;
    .container{
        width: 90%;
        max-width: 1200px;
        margin: 0 auto;
        position: relative;
        h2{
            font-size: 96px;
            font-weight: 600;
            line-height: 100%;
            letter-spacing: -4.8px;
            margin: 0px 0px 3.125rem;
        }
        p{
            font-size: 32px;
            font-weight: 400;
            letter-spacing: -1.2px;
            line-height: 140%;
        }
        .p-image{
            img{
                border-radius: 26px;
                width: 100%;
            }
        }
    }
}
.who-is-section{
    padding: 3rem 0rem 2rem;
    color: $white;
    text-align: center;
    .container{
        width: 90%;
        max-width: 1468px;
        margin: 0 auto;
        position: relative;
        h2{
            font-size: 96px;
            font-weight: 600;
            line-height: 100%;
            letter-spacing: -4.8px;
            margin: 0px 0px 3.125rem;
        }
        p{
            font-size: 36px;
            font-weight: 400;
            letter-spacing: -1.8px;
        }
        .option{
            text-align: center;
            .p-image{
                line-height: 0px;
                img{
                    height: 120px;
                }
            }
            p{
                font-size: 36px;
                font-weight: 400;
                letter-spacing: -1.8px;
            }
        }
        .custom-margin{
            margin: 6rem 0rem;
        }
        .apply-button{
            background: $accent;
            border-radius:23px;
            transition: 0.8s;
            padding: 35px 66px;
            border: none;
            width: 100%;
            max-width: 432px;
            .p-button-label{
                font-size: 36px;
                font-weight: 700;
                position: relative;
                z-index: 2;
                color: $white;
                line-height: 103%;
                letter-spacing: -1.8px;

            }
            &:hover{
                background: $primary;
            }
        }
    }
}
.how-it-works-section{
    padding: 8rem 0rem 12rem;
    color: $white;
    text-align: center;
    .container{
        width: 90%;
        max-width: 1468px;
        margin: 0 auto;
        position: relative;
        h2{
            font-size: 96px;
            font-weight: 4600;
            line-height: 100%;
            letter-spacing: -4.8px;
            margin: 0px 0px 3.125rem;
        }
        .info-block{
            width: 100%;
            max-width: 300px;
            margin: auto;
            position: relative;
            span{
                color: $primary;
                font-family: "EB Garamond";
                font-size: 128px;
                font-weight: 700;
                line-height: 103.971%;
                letter-spacing: -6.4px;
            }
            h3{
                font-size: 48px;
                font-weight: 600;
                line-height: 100%;
                letter-spacing: -2.4px;
                margin: 10px 0px 25px;
            }
            p{
                font-size: 24px;
                font-weight: 400;
                line-height: 100%;
                letter-spacing: -1.2px;
                margin: 0px;
            }
            &::before{
                content: '';
                position: absolute;
                width: 85px;
                height: 16px;
                background: url('../../assets/images/arrow.png') no-repeat;
                right: -9rem;
                top: 50%;
            }
            &.last{
                &::before{
                    display: none;
                }
            }
        }
    }
}
.faqs-section{
    width: 90%;
    max-width: 1685px;
    margin: 0 auto;
    padding: 10rem 0rem;
    color: $white;
    h2{
        font-size: 58px;
        font-weight: 600;
        line-height: 100%;
        letter-spacing: -2.9px;
        margin: 0px;
    }
    p{
        font-size: 25px;
        font-weight: 400;
        line-height: 30px;
    }
    .custom-margin{
        margin-top: 8.5rem;
    }
    .faq-wrapper{
        width: 100%;
        max-width: 660px;
        .faq-list{
            margin-bottom: 4.2125rem;
            position: relative;
            padding-left: 50px;
            h3{
                font-size: 28px;
                font-weight: 600;
                line-height: 39.314px;
                letter-spacing: -1.404px;
                margin: 0px 0px 12px;
            }
            p{
                font-size: 22px;
                font-weight: 400;
                line-height: 33.698px;
                letter-spacing: -1.123px;
                margin: 0px;
                a{
                    color: $white;
                    font-weight: 700;
                    &:hover{
                        color: $primary;
                    }
                }
            }
            &::before{
                content: '';
                position: absolute;
                width: 22px;
                height: 22px;
                background: $primary;
                transform: rotate(45deg);
                left: 5px;
                top: 12px;
            }
        }
        .faq-button{
            background: $accent;
            border-radius: 8px;
            transition: 0.8s;
            padding: 24px 20px;
            border: none;
            margin-left: 50px;
            width: 100%;
            max-width: 600px;
            .p-button-label{
                font-size: 23px;
                font-weight: 600;
                position: relative;
                z-index: 2;
                line-height: 24px;
            }
            &:hover{
                background: $primary;
            }
        }
    }
    .faq-image{
        display: block;
        line-height: 0px;
        img{
            width: 100%;
            max-width: 715px;
            border-radius: 42px;
        }
    }
}
.affiliate-section{
    .title-h2{
        font-size: 64px;
        font-weight: 400;
        line-height: 105%;
        letter-spacing: -3.2px;
        text-align: center;
        color: $white;
        margin-bottom: 6.5rem;
    }
}

// Responsive CSS

@media (max-width:991px){
    .header-banner-section{
        padding: 4rem 0rem;
        h1{
            font-size: 38px;
            line-height: 120%;
            letter-spacing: -1.9px;
        }
        p{
            font-size: 18px;
            letter-spacing: -0.9px;
            padding: 0rem;
        }
        .open-button{
            padding: 14px 50px;
            height: auto;
            border-radius: 10px;
            margin-top: 40px;
            .p-button-label {
                font-size: 18px;
                font-weight: 700;
                line-height: 104%;
                letter-spacing: -0.9px;
            }
        }
    }
    .life-at-bluehouse-section{
        padding: 0rem 0rem;
        .life-at-bluehouse-container{
            h2{
                font-size: 34px;
                line-height: 14%;
                letter-spacing: -1.7px;
            }
            .mobile-flex{
                flex-direction: column;
                .right{
                    display: flex;
                    gap:10px;
                    .p-image img{
                        height: 250px;
                        object-fit: cover;
                    }
                }
            }
        }
        &::before{
            width: 100px;
            height: 100px;
        }
        &::after{
            width: 100px;
            height: 100px;
        }
    }
    .review-section{
        padding: 4rem 0rem;
        .review-container{
            .user-avator img{
                padding: 5px;
                width: 110px;
            }
            h2{
                font-size: 25px;
                line-height: 128%;
                letter-spacing: -1.252px;
            }
            p{
                text-align: center !important;
                font-size: 18px;
                line-height: 105%;
                letter-spacing: -0.9px;
            }
        }
    }
    .our-values-section {
        padding: 4rem 0rem;
        h2{
            font-size: 42px;
            line-height: 105%;
            letter-spacing: -2.1px;
        }
        .p-accordion{
            margin-top: 3rem;
            .p-accordion-tab{
                .p-accordion-header{
                    .p-accordion-header-link{
                        border-radius: 15px;
                        padding: 27px 35px;
                        .p-accordion-header-text{
                            font-size: 27px;
                            line-height: 103.971%;
                            letter-spacing: -1.351px;
                        }
                    }
                }
                &.p-accordion-tab-active{
                    padding: 27px 35px;
                    flex-direction: column;
                    justify-content: flex-start;
                    min-height: auto;
                    .p-accordion-header{
                        max-width: 100%;
                    }
                }
                .p-toggleable-content{
                    max-width: 100%;
                    .p-accordion-content{
                        p{
                            font-size: 17px;
                            letter-spacing: -0.823px;
                        }
                    }
                }
            }
        }
    }
    .positions-section {
        padding: 3rem 0rem;
        .text-left{
            text-align: center !important;
        }
        h2{
            font-size: 38px;
            line-height: 105%;
            letter-spacing: -2.1px;
            margin-bottom: 3rem;
        }
        .job-list{
            margin-bottom: 4rem;
            h3{
                font-size: 32px;
                line-height: 105%;
                letter-spacing: -1.6px;
                &.location-h3{
                    font-size: 20px;
                    line-height: 105%;
                    letter-spacing: -1px;
                    .pi-map-marker{
                        font-size: 20px;
                    }
                }
            }
            p{
                font-size: 18px;
                line-height: 130%;
                letter-spacing: -0.9px;
            }
            .more-link{
                font-size: 18px;
                line-height: 103.971%;
                letter-spacing: -0.9px;
                .pi-download{
                    width: 26px;
                    height: 26px;
                    font-size: 13px;
                }
            }
            .apply-button{
                height: auto;
                padding: 18px 60px;
                border-radius: 15px;
                .p-button-label{
                    font-size: 18px;
                    line-height: 103.971%;
                    letter-spacing: -0.9px;
                }
            }
            .text-right{
                text-align: left !important;
            }
        }
    }
    .location-map-section{
        padding: 4rem 0rem;
        h3{
            font-size: 20px;
            line-height: 104%;
            letter-spacing: -1px;
        }
    }
    .team-section{
        padding: 4rem 0rem;
        h2{
            font-size: 42px;
            line-height: 105%;
            letter-spacing: -2.1px;
            margin-bottom: 2rem;
        }
        .p-image img{
            margin-top: 1rem;
        }
    }
    .how-it-works-section{
        text-align: left;
        padding: 3rem 0rem;
        .container{
            h2{
                font-size: 34px;
                line-height: 104%;
                letter-spacing: -1.7px;
                margin: 0px 0px 2rem;
            }
            .p-image img{
                height: 300px;
                object-fit: cover;
            }
            p{
                font-size: 18px;
                letter-spacing: -0.9px;
            }
            .info-block{
                margin-bottom: 2rem;
                h3{
                    font-size: 30px;
                    line-height: 120%;
                    letter-spacing: -1.5px;
                }
                p{
                    font-size: 18px;
                    letter-spacing: -0.9px;
                    line-height: 130%;
                }
                span{
                    font-size: 58px;
                    line-height: 103%;
                    letter-spacing: -2.9px;
                }
                &::before{
                    display: none;
                }
            }
        }
    }
    .who-is-section{
        padding: 3rem 0rem;
        .container{
            h2{
                font-size: 34px;
                line-height: 104%;
                letter-spacing: -1.7px;
                margin: 0px 0px 2rem;
            }
            .option{
                p{
                    font-size: 19px;
                    letter-spacing: -0.964px;
                }
                .p-image img{
                    height: 110px;
                }
            }
            .apply-button{
                padding: 18px 50px;
                height: auto;
                border-radius: 18px;
                margin-top: 0px;
                max-width: 75%;
                .p-button-label {
                    font-size: 18px;
                    font-weight: 700;
                    line-height: 104%;
                    letter-spacing: -0.9px;
                }
            }
            .custom-margin{
                margin: 3rem 0rem;
            }
        }
    }
    .faqs-section{
        padding: 1rem 0rem 3rem;
        h2{
            font-size: 42px;
            line-height: 105%;
            letter-spacing: -2.1px;
        }
        p{
            font-size: 18px;
            line-height: 130%;
            letter-spacing: -0.9px;
        }
        .custom-margin{
            margin-top: 5rem;
        }
        .faq-wrapper .faq-list{
            h3{
                font-size: 26px;
                line-height: 150%;
                letter-spacing: -1px;
            }
            p{
                font-size: 18px;
                line-height: 30px;
            }
        }
    }
}

@media (min-width:768px) and (max-width:991px){
    .life-at-bluehouse-section{
        .life-at-bluehouse-container{
            .mobile-flex{
                flex-direction: row;
                .right{
                    flex-direction: column;
                    gap: 0px;
                    .p-image{
                        margin-bottom: 15px;
                        img{
                            height: auto;
                            object-fit: cover;
                        }
                    }
                }
            }
        }
    }
}
@media (min-width: 1200px) and (max-width: 1480px) {
    .header-banner-section .affiliate-h1{
        font-size: 90px;
    }
    .who-is-section .container{
        h2{
            font-size: 64px;
        }
        .option p {
            font-size: 30px;
        }
    }
    .how-it-works-section .container .info-block::before{
        background-size: 60px;
        right: -7rem;
    }
}

@media (max-width:960px){
    .header-banner-section .affiliate-h1{
        font-size: 38px;
        line-height: 120%;
        letter-spacing: -1.9px;
    }
    .affiliate-section{
        padding: 2rem 0rem;
        .title-h2{
            font-size: 42px;
            line-height: 105%;
            letter-spacing: -2.1px;
            margin-bottom: 1.5rem;
        }
        .affiliate-container{
            h2{
                font-size: 38px;
                line-height: 105%;
                letter-spacing: -1.9px;
                margin-top: 0rem;
            }
            .right-block {
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-direction: row-reverse;
            }
            .arrow-icon {
                width: 55px;
                height: 55px;
                .pi {
                    font-size: 24px;
                }
            }
        }
        &::before{
            left: 0rem;
        }
    }
}
@media (min-width: 961px) and (max-width: 1199px) {
    .affiliate-section .affiliate-container h2{
        font-size: 45px;
        line-height: 48px;
        margin-top: 4rem;
    }
    .header-banner-section .affiliate-h1{
        font-size: 65px;
    }
    .how-it-works-section .container .info-block::before{
        display: none;
    }
    .who-is-section .container .option p{
        font-size: 22px;
    }
}
@media (max-width:620px){
    .affiliate-section .title-h2{
        font-size: 24px;
        line-height: 110%;
        letter-spacing: -2px;
    }
}